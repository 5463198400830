import React from 'react'
import MainScreen from '../../Components/mainScreen/MainScreen'
import s from './GeneralInformation.module.css'
import card1 from '../../assets/newImg/generalInformation/img1.png'
import card2 from '../../assets/newImg/generalInformation/img2.png'
import card3 from '../../assets/newImg/generalInformation/img3.png'
import card4 from '../../assets/newImg/generalInformation/img4.png'
import longCard from '../../assets/newImg/generalInformation/long_card.png'
import makeBg from '../../assets/newImg/generalInformation/makeBg.png'
import quotes from '../../assets/newImg/generalInformation/quotes.png'
import { t } from 'i18next'

const GeneralInformation = () => {
  const isMobile = () => {
    if (window.innerWidth < 768) {
      return true
    }
  }

  return (
    <div className={s.generalInformationWrapper}>
      <MainScreen
        title={t('public_routes.general_information')}
        pageName={t('main.about_us')}
      />
      <div className={s.container}>
        <div className={s.cardsWrapper}>
          <div className={s.card}>
            <div className={s.blur}></div>
            <div className={s.cardTitleBlock}>
              <div className={s.cardTitle}>
                {t('general_info.banking_built_differently')}
              </div>
              <div className={s.cardText}>{t('general_info.text1')}</div>
            </div>
            <img src={card1} className={s.cardImg} alt={''} />
          </div>

          <div className={s.card}>
            <div className={s.blur}></div>
            <div className={s.cardTitleBlock}>
              <div className={s.cardTitle}>
                {t('general_info.relationships')}
              </div>
              <div className={s.cardText}>{t('general_info.text2')}</div>
            </div>
            <img src={card2} className={s.cardImg} alt={''} />
          </div>

          <div className={s.card}>
            <div className={s.blur}></div>
            <div className={s.cardTitleBlock}>
              <div className={s.cardTitle}>
                {t('general_info.flexibility_convenience')}
              </div>
              <div className={s.cardText}>{t('general_info.text3')}</div>
            </div>
            <img src={card3} className={s.cardImg} alt={''} />
          </div>

          <div className={s.card}>
            <div className={s.blur}></div>
            <div className={s.cardTitleBlock}>
              <div className={s.cardTitle}>{t('general_info.commitment')}</div>
              <div className={s.cardText}>{t('general_info.text4')}</div>
            </div>
            <img src={card4} className={s.cardImg} alt={''} />
          </div>

          {isMobile() ? (
            <div className={s.card}>
              <div className={s.blur}></div>
              <div className={s.cardTitleBlock}>
                <div className={s.cardTitle}>{t('general_info.longevity')}</div>
                <div className={s.cardText}>{t('general_info.text5')}</div>
              </div>
              <img src={longCard} className={s.cardImg} alt={''} />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className={s.longCard}>
          <div className={s.longBlur}></div>
          <div className={s.longCardTextBlock}>
            <div className={s.longCardTitle}>{t('general_info.longevity')}</div>
            <div className={s.longCardText}>{t('general_info.text5')}</div>
          </div>
          <img src={longCard} alt='' />
        </div>
      </div>
      <div className={s.makeBlock}>
        <div className={s.makeTitle}>{t('general_info.make_life_easier')}</div>
        <div className={s.relative}>
          <img src={makeBg} alt='' />
          <div className={s.makeDescription}>
            <div>{t('general_info.text6')}</div>
            <div>{t('general_info.text7')}</div>
          </div>
        </div>
      </div>
      <div className={s.fake}></div>
      <div className={s.container}>
        <div className={s.commentsBlock}>
          <div className={s.comment}>
            <div>
              <img src={quotes} alt='' />
            </div>
            <div className={s.commentText}>
              As a business owner, I am constantly looking for ways to be more
              dynamic and efficient. Some time ago, we had TIIB set us up with
              their online banking and ‘Remote Capture’ for making deposits.
              With its ease of use and accuracy, it has proved to be a highly
              convenient tool. It saves us many trips to the bank and keeps our
              cash flow moving quicker. Not losing travel time going to and from
              the bank is invaluable.
            </div>
            <div className={s.closeQuotes}>
              <img src={quotes} alt='' />
            </div>
            <div className={s.author}>— Robert N.</div>
          </div>
          <div className={s.comment}>
            <div>
              <img src={quotes} alt='' />
            </div>
            <div className={s.commentText}>
              As a small business owner, I love that the bankers know me and my
              business. I now use them for all of my banking because they’re so
              highly responsive. I know with their team behind me, I can focus
              on what’s important.
            </div>
            <div className={s.closeQuotes}>
              <img src={quotes} alt='' />
            </div>
            <div className={s.author}>— Sofie M.</div>
          </div>
        </div>

        <div className={s.commentsBlockTwo}>
          <div className={s.comment}>
            <div>
              <img src={quotes} alt='' />
            </div>
            <div className={s.commentText}>
              We received the Bank’s number from a friend who suggested we try a
              different bank. Brandon and Mark came out to our office to find
              out more about our business, and when they left, they said they
              could help us.
            </div>
            <div className={s.closeQuotes}>
              <img src={quotes} alt='' />
            </div>
            <div className={s.author}>— Timothy and Angela</div>
          </div>
          <div className={s.comment}>
            <div>
              <img src={quotes} alt='' />
            </div>
            <div className={s.commentText}>
              When I called, I was able to speak with a decisionmaker right
              away. He knew exactly what I needed for my investment property and
              was able to put the loan in place very quickly.
            </div>
            <div className={s.closeQuotes}>
              <img src={quotes} alt='' />
            </div>
            <div className={s.author}>— Ali B.</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GeneralInformation
