import React from 'react'
import MainScreen from '../../Components/mainScreen/MainScreen'
import s from './OrganizationalStructure.module.css'
import card1 from '../../assets/newImg/organizationalStructure/card1.png'
import card2 from '../../assets/newImg/organizationalStructure/card2.png'
import card3 from '../../assets/newImg/organizationalStructure/card3.png'
import card4 from '../../assets/newImg/organizationalStructure/card4.png'
import { t } from 'i18next'

const OrganizationalStructure = () => {
  return (
    <div>
      <MainScreen
        title={t('public_routes.organizational_structure')}
        titleTwo={''}
        pageName={t('main.about_us')}
      />
      <div className={s.wrapper}>
        <div className={s.cardsBlock}>
          <div className={s.card}>
            <img className={s.cardBg} src={card1} alt='' />
            <div className={s.cardTextBlock}>
              <div className={s.cardTitle}>Habil Harun Doğan</div>
              <div className={s.cardText}>
                {t('organizational_structure.head_board')}
              </div>
            </div>
          </div>

          <div className={s.card}>
            <img className={s.cardBg} src={card2} alt='' />
            <div className={s.cardTextBlock}>
              <div className={s.cardTitle}>Manuel Alcaraz</div>
              <div className={s.cardText}>
                {t('organizational_structure.board_member')}
              </div>
            </div>
          </div>

          <div className={s.card}>
            <img className={s.cardBg} src={card3} alt='' />
            <div className={s.cardTextBlock}>
              <div className={s.cardTitle}>Agnesia Kask</div>
              <div className={s.cardText}>
                {t('organizational_structure.board_member')}
              </div>
            </div>
          </div>

          <div className={s.card}>
            <img className={s.cardBg} src={card4} alt='' />
            <div className={s.cardTextBlock}>
              <div className={s.cardTitle}>Lena Kowalski</div>
              <div className={s.cardText}>
                {t('organizational_structure.board_member')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrganizationalStructure
