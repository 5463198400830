import React from 'react';
import s from './MainScreen.module.css'
import mainScreen from '../../assets/newImg/mainScreen.png'

const MainScreen = (props) => {
    return (
        <div className={s.mainScreen}>
            <div className={s.content}>
                <div className={s.container}>
                    <div className={s.pageName}>
                        {props.pageName}
                    </div>
                    <div className={s.titleBlock}>
                        <div className={s.title}>
                            <p>{props.title}&nbsp;</p>
                        </div>
                        <div className={s.line}>

                        </div>
                        <div className={s.title}>
                            {props.titleTwo}
                            <div className={s.line}>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={s.bg}><img src={mainScreen} alt=""/></div>
        </div>
    );
};

export default MainScreen;
