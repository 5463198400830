import React from 'react';
import "./ServicesHoverElement.css";

const ServicesHoverElement = (props) => {
    return (
        <div className="iconBlock">
            <div className={props.class}>

            </div>
            <div className="iconText">
                {props.text}
            </div>
        </div>
    );
};

export default ServicesHoverElement;
