import React from 'react'
import MainScreen from '../../Components/mainScreen/MainScreen'
import s from './UniversalPersonalAccounts.module.css'
import MyBtn from '../../Components/myBtn/MyBtn'
import { DEBIT_CARDS_ACCOUNT_OPENING } from '../../router/Constants'
import { useHistory } from 'react-router-dom'
import { t } from 'i18next'

const UniversalPersonalAccounts = () => {
  const redirect = useHistory()

  return (
    <div>
      <MainScreen
        title={t('public_routes.universal_personal_accounts')}
        titleTwo={''}
        pageName={t('main.banking')}
      />
      <div className={s.container}>
        <div className={s.descriptionBlock}>
          <div className={s.textBlock}>
            {t('universal_personal_accounts.text1')}
          </div>
          <div className={s.line}></div>
          <div className={s.textBlock}>
            {t('universal_personal_accounts.text2')}
          </div>
        </div>
      </div>

      <div className={s.saveTime}>
        <div className={s.saveTimeTitle}>
          {t('universal_personal_accounts.text3')}
        </div>
        <div>
          <MyBtn
            title={t('main.open_account')}
            onClick={() => {
              redirect.push(DEBIT_CARDS_ACCOUNT_OPENING)
            }}
          />
        </div>
      </div>

      <div className={s.container}>
        <div className={s.descriptionTwoLine}>
          <div className={s.lineShort}></div>
          <div className={s.textBlockTwoLine}>
            {t('universal_personal_accounts.text4')}
          </div>
          <div className={s.lineShort}></div>
        </div>
      </div>
    </div>
  )
}

export default UniversalPersonalAccounts
