import React, { useEffect, useState } from 'react'
import s from './StatementTable.module.css'
import { Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { t } from 'i18next'

const StatementTable = ({ accountNumber, statementTableData }) => {
  const state = useSelector(state => state)
  const { UserReducer: user } = state

  const [displayedData, setDisplayedData] = useState([])
  const [displayedAccount, setDisplayedAccount] = useState(accountNumber)

  const accounts = user?.data?.accounts

  useEffect(() => {
    const filteredStatementTableData = statementTableData.filter(
      acc => acc.account_number === displayedAccount
    )
    setDisplayedData(filteredStatementTableData[0].transaction)
  }, [displayedAccount, statementTableData])

  const onSelectAccount = e => {
    setDisplayedAccount(e.target.value)
  }

  return (
    <div>
      <div className={s.select_name}>
        {t('personal.statement_page.filter_title')}
      </div>

      <select
        className={s.my_select}
        value={displayedAccount}
        onChange={onSelectAccount}>
        {accounts.map(acc => {
          return (
            <option
              key={acc.account_special_number}
              value={acc.account_special_number}
              style={{ height: '38px' }}>
              <span>{acc.account_special_number}</span>{' '}
              <span>{acc.currency_abbreviation}</span>
            </option>
          )
        })}
      </select>

      <Table className={s.table_web}>
        <thead className={s.table_titles}>
          <tr className={s.tr}>
            <th className={s.th}>İşlem tarihi</th>
            <th className={s.th}>
              {t('personal.statement_page.transfer_type')}
            </th>
            <th className={s.th}>Onay tarihi</th>
            {/*<th className={s.th}>Sender</th>*/}
            {/*<th className={s.th}>Beneficiary</th>*/}
            <th className={s.th}>{t('personal.statement_page.payment')}</th>
            <th className={s.th}>
              {t('personal.statement_page.transaction_number')}
            </th>
            <th className={s.th}>{t('personal.statement_page.debit')}</th>
            <th className={s.th}>{t('personal.statement_page.credit')}</th>
            <th className={s.th}>{t('personal.statement_page.status')}</th>
            <th className={s.th}>{t('personal.statement_page.balance')}</th>
          </tr>
        </thead>
        {displayedData.length >= 1 ? (
          <tbody>
            {displayedData.map(row => (
              <tr key={row.id} className={s.tr}>
                <td className={s.values}>{row.date.substr(0, 10)}</td>
                <td className={s.values}>{row.transfer_type_name}</td>
                <td className={s.values}>
                  {row.valueDate ? row.valueDate.substr(0, 10) : '--:--:--'}
                </td>
                <td className={s.values}>{row.reference}</td>
                <td className={s.values}>{row.transfer_number}</td>
                <td className={s.values}>{row.debit}</td>
                <td className={s.values}>{row.credit}</td>
                <td
                  className={
                    row.status_name === 'canceled' ? s.canceled : s.confirmed
                  }>
                  {row.status_name}
                </td>
                <td className={s.values}>
                  {row.status_name === 'pending' ? '' : row.balance}
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <div className={s.no_transactions}>Henüz işlem yapılmadı</div>
        )}
      </Table>

      <div className={s.table_mobile}>
        {displayedData.length >= 1 ? (
          <tbody>
            {displayedData.map(item => (
              <div className={s.account_card} key={item?.transfer_number}>
                <div className={s.card_row}>
                  <div className={s.acc_title}>
                    {t('personal.statement_page.date')}:
                  </div>
                  <div className={s.acc_value}>{item?.date.substr(0, 10)}</div>
                </div>
                <div className={s.card_row}>
                  <div className={s.acc_title}>
                    {t('personal.statement_page.transfer_type')}:
                  </div>
                  <div className={s.acc_value}>{item?.transfer_type_name}</div>
                </div>
                <div className={s.card_row}>
                  <div className={s.acc_title}>
                    {t('personal.statement_page.payment')}:
                  </div>
                  <div className={s.acc_value}>{item?.reference}</div>
                </div>
                <div className={s.card_row}>
                  <div className={s.acc_title}>
                    {t('personal.statement_page.transaction_number')}:
                  </div>
                  <div className={s.acc_value}>{item?.transfer_number}</div>
                </div>
                <div className={s.card_row}>
                  <div className={s.acc_title}>
                    {t('personal.statement_page.debit')}:
                  </div>
                  <div className={s.acc_value}>{item?.debit}</div>
                </div>
                <div className={s.card_row}>
                  <div className={s.acc_title}>
                    {t('personal.statement_page.credit')}:
                  </div>
                  <div className={s.acc_value}>{item?.credit}</div>
                </div>
                <div className={s.card_row}>
                  <div className={s.acc_title}>
                    {t('personal.statement_page.status')}:
                  </div>
                  <div className={s.acc_value}>{item?.status_name}</div>
                </div>
                <div className={s.card_row}>
                  <div className={s.acc_title}>
                    {t('personal.statement_page.balance')}:
                  </div>
                  <div className={s.acc_value}>{item?.min_balance}</div>
                </div>
              </div>
            ))}
          </tbody>
        ) : (
          <div className={s.no_transactions}>Henüz işlem yapılmadı</div>
        )}
      </div>
    </div>
  )
}

export default withTranslation()(StatementTable)
