import React from 'react'
import MainScreen from '../../Components/mainScreen/MainScreen'
import s from './ComplianceAndDocuments.module.css'
import pdfImg from '../../assets/newImg/pdf.png'
import { t } from 'i18next'

const ComplianceAndDocuments = () => {
  return (
    <div>
      <MainScreen
        title={t('public_routes.compliance_and_documents')}
        titleTwo={''}
        pageName={t('main.about_us')}
      />
      <div className={s.container}>
        <div className={s.documentWrapper}>
          <div className={s.flex}>
            <img src={pdfImg} alt='' />
            <div className={s.docName}>
              {t('compliance_and_documents.pdf1')}
            </div>
          </div>
          <div className={s.flex}>
            <a href='/docs/TIIB-data-protection-policy.pdf' target='_blank'>
              <button className={s.open}>
                {t('compliance_and_documents.open')}
              </button>
            </a>
            <a href='/docs/TIIB-data-protection-policy.pdf' download>
              <button className={s.download}>
                {t('compliance_and_documents.download')}
              </button>
            </a>
          </div>
        </div>
        <div className={s.documentWrapper}>
          <div className={s.flex}>
            <img src={pdfImg} alt='' />
            <div className={s.docName}>
              {t('compliance_and_documents.pdf2')}
            </div>
          </div>

          <div className={s.flex}>
            <a href='/docs/TIIB-policy.pdf' target='_blank'>
              <button className={s.open}>
                {t('compliance_and_documents.open')}
              </button>
            </a>
            <a href='/docs/TIIB-policy.pdf' download>
              <button className={s.download}>
                {t('compliance_and_documents.download')}
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ComplianceAndDocuments
