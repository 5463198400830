import React, {useState} from 'react';
import s from "./DropdownElement.module.css";
import {NavLink} from "react-router-dom";
import classnames from "classnames";

const DropdownElement = (props) => {

    const [isDropdownLinksVisible, setIsDropdownLinksVisible] = useState(false)

    const isMobile = () => {
        if (window.innerWidth < 768) {
            return true
        }
    }

    const closeMenu = () => {
        props.setMenuOpen(false)
    }

    return (
        <div
            className={s.header_link}
        >
            <div className={s.link}
                 onMouseOver={() => setIsDropdownLinksVisible(true)}
                 onMouseOut={() => setIsDropdownLinksVisible(false)}
            >
                {props.name}
            </div>
            <div className={classnames(s.dropdown_content, s.header_dropdown_link)}
                 onMouseOver={() => setIsDropdownLinksVisible(true)}
                 onMouseOut={() => setIsDropdownLinksVisible(false)}
            >
                { isDropdownLinksVisible
                    ? props.items.map((linkItem) => <div className={s.mb} key={linkItem.path}>
                        {
                            isMobile() ? <NavLink className={s.droplink} to={linkItem.path} onClick={() => {closeMenu()}}>
                                            {linkItem.title}
                                         </NavLink>
                                        : <NavLink className={s.droplink} to={linkItem.path} >
                                            {linkItem.title}
                                          </NavLink>
                        }

                        </div>
                    )
                    : <></>
                }
            </div>
        </div>
    );
};

export default DropdownElement;
