import React, { useState } from 'react'
import s from './Header.module.css'
import logo from '../../assets/newImg/logo.png'
import LoginBtnMobile from '../../assets/newImg/Log_in_mob.png'
import burgerMenu from '../../assets/newImg/Menu.png'
import logoMob from '../../assets/newImg/logo_mob.svg'
import login from '../../assets/newImg/Log_in.png'
import { useDispatch } from 'react-redux'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { Helper } from '../../utils/helper'
import { authSlice } from '../../Reducers/AuthReducer'
import { userSlice } from '../../Reducers/UserReducer'
import MyPopup from '../../utils/MyPopup/MyPopup'
import { withTranslation } from 'react-i18next'
import i18n from '../../i18n'
import { t } from 'i18next'
import Sidebar from '../sidebar/Sidebar'
import classnames from 'classnames'
import DropdownElement from '../dropdownElement/DropdownElement'
import { HOME_ROUTE } from '../../router/Constants'
import {
  aboutUsRoutes,
  bankingRoutes,
  contactUsRoutes,
  debitCardsRoutes,
  eMoneyRoutes,
  savingsAccountRoutes,
} from '../../router/PublicRoutes'

const Header = ({ auth }) => {
  let dispatch = useDispatch()
  const location = useLocation()
  const { logout } = authSlice.actions
  const { clearState } = userSlice.actions

  const [popupIsVisible, setPopupIsVisible] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const shouldHide = () => {
    return (
      location.pathname === '/login' ||
      location.pathname === '/signup' ||
      location.pathname.includes('personal')
    )
  }

  const isHomePage = () => {
    return location.pathname === '/'
  }

  const handleLogout = () => {
    dispatch(logout())
    dispatch(clearState())
  }

  const handleChangeLanguage = e => {
    const lang = e.target.textContent.toLowerCase()
    i18n.changeLanguage(lang)
    localStorage.setItem('lang', lang)
    window.location.reload()
  }

  // check should hide
  if (shouldHide()) {
    return <div></div>
  }

  const getLogoMob = () => {
    return <img src={logoMob} alt='logo' className={s.logo_mob} />
  }

  const getLogo = () => {
    // if (!isHomePage()) {
    return (
      <Link to='/'>
        <img src={logo} alt='' />
      </Link>
    )
    // }
  }

  // if (isHomePage()) {
  //     return <div>
  //         <div className={s.headerWrapper} id={'1'}>
  //             <div className={s.headerContainer}>
  //                 <div className={s.left}>
  //                     <Link to="/">
  //                         { getLogo() }
  //                     </Link>
  //                     <Link to="/login">
  //                         <div className={s.loginButtonBlock}>
  //                             <img src={login} alt="login"/>
  //                             <span className={s.login}>{t('main.login')}</span>
  //                         </div>
  //                     </Link>
  //                     <div>
  //                         <button className={s.signUpButton}
  //                                 onClick={() => {
  //                                     setPopupIsVisible(true)
  //                                 }}
  //                         >{t('main.sign_up')}
  //                         </button>
  //                     </div>
  //                     <div className={s.lng_block}>
  //                         <div className={localStorage.getItem('lang') === 'en' ? s.active_lang : s.lng}
  //                              onClick={handleChangeLanguage}>
  //                             EN
  //                         </div>
  //                         <div className={localStorage.getItem('lang') === 'ru' ? s.active_lang : s.lng}
  //                              onClick={handleChangeLanguage}>
  //                             RU
  //                         </div>
  //                         <div className={localStorage.getItem('lang') === 'tr' ? s.active_lang : s.lng}
  //                              onClick={handleChangeLanguage}>
  //                             TR
  //                         </div>
  //                     </div>
  //                 </div>
  //                 <div className={s.right}>
  //
  //                     <NavLink to="/">
  //                         <button className={s.my_btn}>
  //                             <div className={s.arr_back}></div>
  //                             {t('login.back')}
  //                         </button>
  //                     </NavLink>
  //
  //                     <MyPopup visible={popupIsVisible} setVisible={setPopupIsVisible}/>
  //
  //                     {Helper.checkAuth(auth) ? (
  //                         <div className={s.dropdown}>
  //                             <div className={`${s.loginButtonBlock} ${s.dropBtn}`}>
  //                                 {auth?.user?.name || "Account"}{" "}
  //                             </div>
  //                             <div className={s.dropdownContent}>
  //                                 <Link to="/personal">Account</Link>
  //                                 <Link to="" onClick={handleLogout}>
  //                                     Logout
  //                                 </Link>
  //                             </div>
  //                         </div>
  //                     ) : (
  //                         <>
  //
  //                         </>
  //                     )}
  //                 </div>
  //             </div>
  //         </div>
  //
  //         <div className={s.header_container_mobile}>
  //             <img src={burgerMenu} alt=""
  //                  className={s.burger_menu_btn}
  //                  onClick={() => {setIsMenuOpen(true)}}
  //             />
  //             <Sidebar pageWrapId={'page-wrap'}
  //                      outerContainerId={'outer-container'}
  //                      isMenuOpen={isMenuOpen}
  //                      setIsMenuOpen={setIsMenuOpen}
  //             />
  //             <Link to="/">
  //                 <div className={s.mob_logo_wrapper}>
  //                     {localStorage.getItem('lang') === 'en'
  //                         ? <img src={logo} alt="logo" className={s.logo_mob}/>
  //                         : <img src={logoTrWhite} alt="logo" className={s.logo_mob}/>
  //                     }
  //                 </div>
  //             </Link>
  //             <Link to="/login">
  //                 <img src={LoginBtnMobile} className={s.login_btn_mob} alt={'log in'}/>
  //             </Link>
  //         </div>
  //     </div>;
  // }

  const dropdownItemsArr = [
    {
      name: t('main.about_us'),
      items: aboutUsRoutes,
    },
    {
      name: t('main.banking'),
      items: bankingRoutes,
    },
    {
      name: t('main.savings_account'),
      items: savingsAccountRoutes,
    },
    {
      name: t('main.debit_cards'),
      items: debitCardsRoutes,
    },
    {
      name: t('main.e_money'),
      items: eMoneyRoutes,
    },
    {
      name: t('main.contact_us'),
      items: contactUsRoutes,
    },
  ]

  return (
    <div className={s.headerWrapper} id={'1'}>
      <div className={s.headerContainer}>
        <div className={s.header_block}>
          <div className={s.logoBlock}>{getLogo()}</div>
          <div className={s.lng_block}>
            <div
              className={
                localStorage.getItem('lang') === 'en' ? s.active_lang : s.lng
              }
              onClick={handleChangeLanguage}>
              EN
            </div>
            <div
              className={
                localStorage.getItem('lang') === 'ru' ? s.active_lang : s.lng
              }
              onClick={handleChangeLanguage}>
              RU
            </div>
            <div
              className={
                localStorage.getItem('lang') === 'tr' ? s.active_lang : s.lng
              }
              onClick={handleChangeLanguage}>
              TR
            </div>
          </div>
        </div>
        <div className={classnames(s.dropdown_block, s.header_block)}>
          <NavLink to={HOME_ROUTE}>
            <div className={s.header_link}>
              <div className={s.link}>{t('public_routes.main_page')}</div>
            </div>
          </NavLink>

          {dropdownItemsArr.map(item => (
            <DropdownElement
              name={item.name}
              items={item.items}
              key={item.name}
            />
          ))}
          {/*<div*/}
          {/*    className={s.header_link}*/}
          {/*>*/}
          {/*    <div className={s.link}>main page</div>*/}
          {/*</div>*/}

          {/*<div*/}
          {/*    className={s.header_link}*/}
          {/*>*/}
          {/*    <div className={s.link}>about us</div>*/}
          {/*</div>*/}

          {/*<div*/}
          {/*    className={s.header_link}*/}
          {/*>*/}
          {/*    <div className={s.link}>banking</div>*/}
          {/*</div>*/}

          {/*<div*/}
          {/*    className={s.header_link}*/}
          {/*>*/}
          {/*    <div className={s.link}>savings account</div>*/}
          {/*</div>*/}

          {/*<div*/}
          {/*    className={s.header_link}*/}
          {/*>*/}
          {/*    <div className={s.link}>debit cards</div>*/}
          {/*</div>*/}

          {/*<div*/}
          {/*    className={s.header_link}*/}
          {/*>*/}
          {/*    <div className={s.link}>e-money</div>*/}
          {/*</div>*/}

          {/*<div*/}
          {/*    className={s.header_link}*/}
          {/*>*/}
          {/*    <div className={s.link}>contact us</div>*/}
          {/*</div>*/}

          <MyPopup visible={popupIsVisible} setVisible={setPopupIsVisible} />

          {Helper.checkAuth(auth) ? (
            <div className={s.dropdown}>
              <div className={`${s.loginButtonBlock} ${s.dropBtn}`}>
                {auth?.user?.name || 'Account'}{' '}
              </div>
              <div className={s.dropdownContent}>
                <Link to='/personal'>Account</Link>
                <Link to='' onClick={handleLogout}>
                  {t('main.logout')}
                </Link>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className={s.header_block}>
          <Link to='/login'>
            <div className={s.loginButtonBlock}>
              <img src={login} alt='login' />
              <span className={s.login}> {t('main.login')}</span>
            </div>
          </Link>
          <div>
            <button
              className={s.signUpButton}
              onClick={() => {
                setPopupIsVisible(true)
              }}>
              {t('main.sign_up')}
            </button>
          </div>
        </div>
      </div>
      <div className={s.header_container_mobile}>
        <img
          src={burgerMenu}
          alt=''
          className={s.burger_menu_btn}
          onClick={() => {
            setIsMenuOpen(true)
          }}
        />
        <Sidebar
          pageWrapId={'page-wrap'}
          outerContainerId={'outer-container'}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
        />
        <Link to='/'>
          <div className={s.mob_logo_wrapper}>{getLogoMob()}</div>
        </Link>
        <Link to='/login'>
          <img
            src={LoginBtnMobile}
            className={s.login_btn_mob}
            alt={'log in'}
          />
        </Link>
      </div>
    </div>
  )
}

export default withTranslation()(Header)
