import React from 'react';
import s from './MyBtn.module.css'

const MyBtn = ({title,...props}) => {
    return (
        <button className={s.my_btn} {...props}>
            {title}
        </button>
    )
}

export default MyBtn
