import axios from "axios";

const baseSettings = {
    headers: {
        'Content-Type': 'application/json',
    },
}

const instance = axios.create({
    baseURL: 'https://api.trkbusecure.com:8080/',
    ...baseSettings
})

const instanceSender = axios.create({
    baseURL: 'https://assembly.tehnik.tech/',
    ...baseSettings
})

export const AuthAPI = {
    login(data) {
        return instance.post(`/api/login_check`, data, baseSettings)
    },
    getUsersData(token) {
        return instance.get('/user/data', {headers: {Authorization: `Bearer ${token}`}})
    },
    getUserAccounts(token) {
        return instance.post('/api/transfeers/gets/users/accounts/details', {}, {headers: {Authorization: `Bearer ${token}`}})
    },
    sendExchangeTransfer(token, data) {
        return instance.post('/api/transfeers/internals/applications', data, {headers: {Authorization: `Bearer ${token}`}})
    },
    createSwiftTransfer(token, data) {
        return instance.post('/api/transfeers/payments/requests/applications', data, {headers: {Authorization: `Bearer ${token}`}})
    },
    createInternalTransfer(token, data) {
        return instance.post('/api/transfeers/internals/a2as/applications', data, {headers: {Authorization: `Bearer ${token}`}})
    },
    send(data) {
        return instanceSender.post(`/api/send_form/from`, data, baseSettings)
    },
    confirmPin(token, pin) {
        return instance.post(`/user/verifications`, {pin}, {headers: {Authorization: `Bearer ${token}`}})
    },
    requestPinCode(token) {
        return instance.post(`/user/sends/users/pins`,{},{headers: {Authorization: `Bearer ${token}`}})
    }
}


// post_send_user_pin                        POST     ANY      ANY    /user/sends/users/pins
// post_verification                         POST     ANY      ANY    /user/verifications
// get_data                                  GET      ANY      ANY    /user/data
// post_change_password                      POST     ANY      ANY    /user/changes/passwords
// post_user_create                          POST     ANY      ANY    /admin/users/creates
// post_add_account                          POST     ANY      ANY    /admin/adds/accounts
// post_get_user_list                        POST     ANY      ANY    /admin/gets/users/lists
// post_get_user_accounts_list               POST     ANY      ANY    /admin/gets/users/accounts/lists
// post_account_operation                    POST     ANY      ANY    /admin/accounts/operations
// post_get_countries_list                   POST     ANY      ANY    /admin/gets/countries/lists
// post_get_full_params_for_account          POST     ANY      ANY    /admin/gets/fulls/params/fors/accounts
// post_get_intra_transfers_list             POST     ANY      ANY    /admin/gets/intras/transfers/lists
// post_change_intra_transfer_status         POST     ANY      ANY    /admin/changes/intras/transfers/statuses
// post_get_beneficiary_transfers_list       POST     ANY      ANY    /admin/gets/beneficiaries/transfers/lists
// post_change_beneficiary_transfer_status   POST     ANY      ANY    /admin/changes/beneficiaries/transfers/statuses
// post_get_accounts_list                    POST     ANY      ANY    /admin/gets/accounts/lists
// post_add_balance                          POST     ANY      ANY    /admin/adds/balances
// post_get_account_type_list                POST     ANY      ANY    /admin/gets/accounts/types/lists
// post_get_transaction_list                 POST     ANY      ANY    /admin/gets/transactions/lists
// post_reset_password                       POST     ANY      ANY    /admin/resets/passwords
// post_update_account_data                  POST     ANY      ANY    /admin/updates/accounts/data
// post_update_user_data                     POST     ANY      ANY    /admin/updates/users/data
// api_login_check                           ANY      ANY      ANY    /api/login_check
// post_online_registration                  POST     ANY      ANY    /mailer/onlines/registrations
// post_contact_form                         POST     ANY      ANY    /mailer/contacts/forms
// post_internal_application                 POST     ANY      ANY    /api/transfeers/internals/applications
// post_payment_request_application          POST     ANY      ANY    /api/transfeers/payments/requests/applications
// post_get_user_accounts_details            POST     ANY      ANY    /api/transfeers/gets/users/accounts/details
// post_shop_payment_transaction             POST     ANY      ANY    /shop_api/shops/payments/transactions
// post_test                                 POST     ANY      ANY    /shop_api/tests

