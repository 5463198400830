import {combineReducers, configureStore} from "@reduxjs/toolkit";
import UserReducer from "../Reducers/UserReducer";
import AuthReducer from "../Reducers/AuthReducer";

const rootReducer = combineReducers ({
    AuthReducer,
    UserReducer
})

export const store = configureStore({
    reducer:rootReducer
})
