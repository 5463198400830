import axios from 'axios';

const settings = {
    headers : {"Content-Type" : "multipart/form-data"},
}

const instance = axios.create({
    baseURL : 'https://api.trkbusecure.com:8080/',
    ...settings
})

export const API = {
    sendUserData(data) {
        return instance.post('/mailer/contacts/forms', data)
    }
}
