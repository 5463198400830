import React from 'react'
import MainScreen from '../../Components/mainScreen/MainScreen'
import s from './VisaAndUnion.module.css'
import cardImg from '../../assets/newImg/debitCards/cardImg.png'
import MyBtn from '../../Components/myBtn/MyBtn'
import { DEBIT_CARDS_ACCOUNT_OPENING } from '../../router/Constants'
import { useHistory } from 'react-router-dom'
import { t } from 'i18next'

const VisaAndUnion = () => {
  const redirect = useHistory()

  return (
    <div>
      <MainScreen
        title={t('public_routes.visa_and_union_pay_debit_cards')}
        titleTwo={''}
        pageName={t('main.debit_cards')}
      />

      <div className={s.wrapper}>
        <div className={s.descBlock}>
          <div className={s.title}>
            {t('visa_and_union_pay_debit_cards.title')}
          </div>
          <div className={s.text}>
            {t('visa_and_union_pay_debit_cards.subtitle')}
          </div>
          <div className={s.buttonWrapperMobile}>
            <MyBtn
              title={t('main.open_account')}
              onClick={() => {
                redirect.push(DEBIT_CARDS_ACCOUNT_OPENING)
              }}
            />
          </div>
        </div>
        <img className={s.cardImg} src={cardImg} alt='' />
      </div>
    </div>
  )
}

export default VisaAndUnion
