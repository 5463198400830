import React, {useState} from "react";
import Account from "../../Account";
import "./InternalTransfer.module.css";
import s from "./InternalTransfer.module.css";
import {AuthAPI} from "../../../Api/Api";
import MyPopupSuccess from "../../../../utils/MyPopupSuccess/MyPopupSuccess";
import {withTranslation} from "react-i18next";
import {t} from "i18next";

const InternalTransfer = () => {

    const [internalTransferData, setInternalTransferData] = useState({
        amount: '',
        from_account: '',
        to_account: '',
        reference: ''
    })
    const [popupVisible, setPopupVisible] = useState(false)

    const sendInternalTransferData = () => {
        const token = localStorage.getItem('token')
        AuthAPI.createInternalTransfer(token, internalTransferData)
            .then(() => {
            })
        setPopupVisible(true)
    }

    const getTermsAndConditionsLink = () => {
        if (localStorage.getItem('lang') === 'en') {
            return "/TURKISH_INTERNATIONAL_INVESTMENT_BANK_LIMITED_TERMS_AND_CONDITIONS.pdf"
        } else if (localStorage.getItem('lang') === 'tr') {
            return "/TURKIYE_UYB_SARTLAR_VE_KOSULLAR.pdf"
        } else {
            return "/Условия_и_Положения.pdf"
        }
    }

    return (
        <Account
            activeTab="internal-transfer"
            title={t('personal.exchange_and_internal_page.internal_title')}
        >
            <MyPopupSuccess visible={popupVisible} setVisible={setPopupVisible}/>
            <div className={s.row}>
                <div className={s.fields}>
                    <div>
                        <div className={s.spanBlock}>
                            <span>{t('personal.exchange_and_internal_page.from_acc')}</span>
                        </div>
                        <div>
                            <input
                                className={s.fields__input}
                                placeholder={t('personal.exchange_and_internal_page.from_acc')}
                                value={internalTransferData.from_account}
                                onChange={(e) => {
                                    setInternalTransferData({
                                        ...internalTransferData,
                                        from_account: e.currentTarget.value
                                    })
                                }}
                            />
                        </div>
                    </div>
                </div>


                <div className={s.fields}>
                    <div>
                        <div className={s.spanBlock}>
                            <span>{t('personal.exchange_and_internal_page.to_acc')}</span>
                        </div>
                        <div>
                            <input
                                className={s.fields__input}
                                placeholder={t('personal.exchange_and_internal_page.to_acc')}
                                value={internalTransferData.to_account}
                                onChange={(e) => {
                                    setInternalTransferData({
                                        ...internalTransferData,
                                        to_account: e.currentTarget.value
                                    })
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={s.row}>
                <div className={s.fields}>
                    <div>
                        <div className={s.spanBlock}>
                            <span>{t('personal.exchange_and_internal_page.amount')}</span>
                        </div>
                        <div>
                            <input
                                className={s.fields__input}
                                placeholder={t('personal.exchange_and_internal_page.amount')}
                                value={internalTransferData.amount}
                                onChange={(e) => {
                                    setInternalTransferData({...internalTransferData, amount: e.currentTarget.value})
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className={s.fields}>
                    <div>
                        <div className={s.spanBlock}>
                            <span>{t('personal.exchange_and_internal_page.payment_ref')}</span>
                        </div>
                        <div>
                            <input
                                className={s.fields__input}
                                placeholder={t('personal.exchange_and_internal_page.payment_ref')}
                                value={internalTransferData.reference}
                                onChange={(e) => {
                                    setInternalTransferData({...internalTransferData, reference: e.currentTarget.value})
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className={s.text_center}>
                    <input type="checkbox" className={s.checkbox}/>
                    <span
                        className={s.agree}>{t('personal.swift_page.i_agree_en')} {t('personal.swift_page.i_agree_tu_p1')} {t('personal.swift_page.i_agree_ru_p1')}</span>
                    <a
                        className={s.terms}
                        type="text"
                        target="_blank"
                        href={getTermsAndConditionsLink()}
                    >
                                <span
                                    className={s.terms}>{t('personal.swift_page.terms_tu')} {t('personal.swift_page.terms_en')} {t('personal.swift_page.terms_ru')}</span>
                    </a>
                    <span
                        className={s.agree}> {t('personal.swift_page.i_agree_tu_p2')} {t('personal.swift_page.i_agree_ru_p2')}</span>
                </div>
                <div>
                    <button
                        className={s.my_btn}
                        onClick={sendInternalTransferData}
                    >{t('personal.transfer')}
                    </button>
                </div>
            </div>

        </Account>
    );
};

export default withTranslation()(InternalTransfer)
