import React from 'react'
import MainScreen from '../../Components/mainScreen/MainScreen'
import Calculate from '../../Components/calculate/Calculate'
import s from './CalculateAmountOfInterest.module.css'
import { t } from 'i18next'

const CalculateAmountOfInterest = () => {
  return (
    <div>
      <MainScreen
        title={t('public_routes.calculate_amount_of_interest')}
        titleTwo={''}
        pageName={t('main.savings_account')}
      />

      <div className={s.wrapper}>
        <Calculate />
      </div>
    </div>
  )
}

export default CalculateAmountOfInterest
