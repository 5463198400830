import React from 'react'
import MainScreen from '../../Components/mainScreen/MainScreen'
import s from './BusinessAndYours.module.css'
import bg from '../../assets/newImg/forBusinessAndYours/bg.png'
import { DEBIT_CARDS_ACCOUNT_OPENING } from '../../router/Constants'
import { useHistory } from 'react-router-dom'
import { t } from 'i18next'

const BusinessAndYours = () => {
  const redirect = useHistory()

  return (
    <div>
      <MainScreen
        title={t('public_routes.for_business_and_yours')}
        pageName={t('main.e_money')}
      />

      <div className={s.wrapper}>
        <div>
          <div className={s.title}>{t('for_business_and_yours.text')}</div>
        </div>
        <div className={s.listWrapper}>
          <img src={bg} alt='' />
          <div className={s.list}>
            <div className={s.text}>{t('for_business_and_yours.line1')}</div>
            <div className={s.text}>{t('for_business_and_yours.line2')}</div>
            <div className={s.text}>{t('for_business_and_yours.line3')}</div>
            <div className={s.btnBlock}>
              <button
                className={s.myBtn}
                onClick={() => {
                  redirect.push(DEBIT_CARDS_ACCOUNT_OPENING)
                }}>
                {t('main.open_account')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BusinessAndYours
