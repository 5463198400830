import React from 'react'
import s from './Home.module.css'
import { withTranslation } from 'react-i18next'
// import {t} from "i18next";
import '../../assets/css/main.css'
import MySlider from '../../Components/slider/Slider'
import ServicesHoverElement from '../../Components/servisesHoverElement/ServicesHoverElement'
import banner from '../../assets/newImg/banner.svg'
import companyName from '../../assets/newImg/companyName.svg'
import point from '../../assets/newImg/point.png'
import { t } from 'i18next'

const Home = () => {
  const servicesHoverElements = [
    {
      class: 'swift',
      text: t('banking_services.swift'),
    },
    {
      class: 'safety',
      text: t('banking_services.safety'),
    },
    {
      class: 'crypto',
      text: t('banking_services.crypto'),
    },
    {
      class: 'my_card',
      text: t('banking_services.my_card'),
    },
    {
      class: 'processingCards',
      text: t('banking_services.processingCards'),
    },
    {
      class: 'chart',
      text: t('banking_services.chart'),
    },
  ]

  const servicesHoverElementsRowTwo = [
    {
      class: 'money',
      text: t('banking_services.money'),
    },
    {
      class: 'infinite',
      text: t('banking_services.infinite'),
    },
    {
      class: 'anonymous',
      text: t('banking_services.anonymous'),
    },
    {
      class: 'distance',
      text: t('banking_services.distance'),
    },
    {
      class: 'support',
      text: t('banking_services.support'),
    },
  ]

  return (
    <div className={s.homeWrapper}>
      <div>
        {/*Main Screen start*/}

        <div className={s.main}>
          <MySlider />
        </div>

        {/*Main screen end*/}

        <div className={s.homeContent}>
          <div className={s.descriptionBlock}>
            <div className={s.textBlock}>{t('home.europe_asia_africa')}</div>
            <div className={s.line}></div>
            <div className={s.textBlock}>{t('home.FATCA')}</div>
          </div>
        </div>

        <div className={s.bankingServicesBlock}>
          <div className={s.homeContent}>
            <div className={s.servicesTitle}>
              {t('home.TIIB_provides_corporate')}
            </div>
            <div className={s.desktop}>
              <div className={s.iconsWrapper}>
                {servicesHoverElements.map(item => (
                  <ServicesHoverElement
                    text={item.text}
                    class={item.class}
                    key={Math.random()}
                  />
                ))}
              </div>
              <div className={s.iconsWrapperRowTwo}>
                {servicesHoverElementsRowTwo.map(item => (
                  <ServicesHoverElement
                    text={item.text}
                    class={item.class}
                    key={Math.random()}
                  />
                ))}
              </div>
            </div>
            <div className={s.mobileFlex}>
              {servicesHoverElements.map(item => (
                <ServicesHoverElement
                  text={item.text}
                  class={item.class}
                  key={Math.random()}
                />
              ))}
              {servicesHoverElementsRowTwo.map(item => (
                <ServicesHoverElement
                  text={item.text}
                  class={item.class}
                  key={Math.random()}
                />
              ))}
            </div>
          </div>
        </div>

        <div className={s.homeContent}>
          <div className={s.descriptionTwoLine}>
            <div className={s.lineShort}></div>
            <div className={s.textBlockTwoLine}>
              {t('home.gaming_gambling_forex')}
            </div>
            <div className={s.lineShort}></div>
          </div>
        </div>

        <div className={s.bannerBlock}>
          <img src={banner} className={s.banner} alt='' />
          <img src={companyName} className={s.name} alt='' />
          <div className={s.nameMobile}>TIIB</div>
        </div>

        <div className={s.homeContent}>
          <div className={s.descriptionBlock}>
            <div className={s.textBlock}>
              {t('home.We_provide_various_types_of_accounts')}
            </div>
            <div className={s.line}></div>
            <div className={s.listBlock}>
              <div className={s.pointBlock}>
                <div className={s.point}>
                  <img src={point} alt='' />
                </div>
                <div className={s.pointText}>
                  {t('home.Shared_bank_account_with_a_common_IBAN')}
                </div>
              </div>
              <div className={s.pointBlock}>
                <div className={s.point}>
                  <img src={point} alt='' />
                </div>
                <div className={s.pointText}>
                  {t('home.Dedicated_account_with_SWIFT')}
                </div>
              </div>
              <div className={s.pointBlock}>
                <div className={s.point}>
                  <img src={point} alt='' />
                </div>
                <div className={s.pointText}>
                  {t('home.Merchant_account_with_TIIB_Card')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(Home)
