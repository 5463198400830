import React from 'react'
import MainScreen from '../../Components/mainScreen/MainScreen'
import s from './AcquiringServices.module.css'
import bg from '../../assets/newImg/acquiring/acquiring_content_bg.png'
import bgMobile from '../../assets/newImg/acquiring/acquring_bg_mobile.png'
import point from '../../assets/newImg/point.png'
import { DEBIT_CARDS_ACCOUNT_OPENING } from '../../router/Constants'
import { useHistory } from 'react-router-dom'
import { t } from 'i18next'

const AcquiringServices = () => {
  const redirect = useHistory()

  return (
    <div>
      <MainScreen
        title={t('public_routes.acquiring_services')}
        pageName={t('main.banking')}
      />
      <div className={s.colorWrapper}>
        <div className={s.contentBlock}>
          <img className={s.bgImg} src={bg} alt='' />
          <img className={s.bgMobile} src={bgMobile} alt='' />
          <div className={s.topText}>{t('acquiring_services.text1')}</div>
          <div className={s.bottomText}>{t('acquiring_services.text2')}</div>
        </div>
      </div>
      <div className={s.btnBlock}>
        <button
          className={s.my_btn}
          onClick={() => {
            redirect.push(DEBIT_CARDS_ACCOUNT_OPENING)
          }}>
          {t('main.open_account')}
        </button>
      </div>
      <div className={s.container}>
        <div className={s.descriptionBlock}>
          <div className={s.textBlock}>
            {t('acquiring_services.accounts_types')}
          </div>
          <div className={s.line}></div>
          <div className={s.listBlock}>
            <div className={s.pointBlock}>
              <div className={s.point}>
                <img src={point} alt='' />
              </div>
              <div className={s.pointText}>{t('acquiring_services.type1')}</div>
            </div>
            <div className={s.pointBlock}>
              <div className={s.point}>
                <img src={point} alt='' />
              </div>
              <div className={s.pointText}>{t('acquiring_services.type2')}</div>
            </div>
            <div className={s.pointBlock}>
              <div className={s.point}>
                <img src={point} alt='' />
              </div>
              <div className={s.pointText}>{t('acquiring_services.type3')}</div>
            </div>
            <div className={s.pointBlock}>
              <div className={s.point}>
                <img src={point} alt='' />
              </div>
              <div className={s.pointText}>{t('acquiring_services.type4')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AcquiringServices
