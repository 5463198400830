import React, { useState } from 'react'
import { slide as Menu } from 'react-burger-menu'
import './Sidebar.css'
import { t } from 'i18next'
import { Link, NavLink } from 'react-router-dom'
import login from '../../assets/newImg/Log_in.png'
import CloseImg from '../../assets/newImg/Close.png'
import MyPopup from '../../utils/MyPopup/MyPopup'
import i18n from '../../i18n'
import { HOME_ROUTE } from '../../router/Constants'
import DropdownElement from '../dropdownElement/DropdownElement'
import {
  aboutUsRoutes,
  bankingRoutes,
  debitCardsRoutes,
  savingsAccountRoutes,
} from '../../router/PublicRoutes'

export default props => {
  const [popupIsVisible, setPopupIsVisible] = useState(false)

  const handleChangeLanguage = e => {
    const lang = e.target.textContent.toLowerCase()
    i18n.changeLanguage(lang)
    localStorage.setItem('lang', lang)
    window.location.reload()
  }

  const dropdownItemsArr = [
    {
      name: t('main.about_us'),
      items: aboutUsRoutes,
    },
    {
      name: t('main.banking'),
      items: bankingRoutes,
    },
    {
      name: t('main.savings_account'),
      items: savingsAccountRoutes,
    },
    {
      name: t('main.debit_cards'),
      items: debitCardsRoutes,
    },
    // {
    //     name: "E-Money",
    //     items: eMoneyRoutes
    // },
    // {
    //     name: "contact us",
    //     items: contactUsRoutes
    // },
  ]

  return (
    <Menu isOpen={props.isMenuOpen}>
      <MyPopup visible={popupIsVisible} setVisible={setPopupIsVisible} />

      <div
        className='close_btn'
        onClick={() => {
          props.setIsMenuOpen(false)
        }}>
        <img src={CloseImg} alt='' />
      </div>

      <div className='lng_block_mob'>
        <div
          className={
            localStorage.getItem('lang') === 'en' ? 'active_lang' : 'lng'
          }
          onClick={handleChangeLanguage}>
          EN
        </div>
        <div
          className={
            localStorage.getItem('lang') === 'ru' ? 'active_lang' : 'lng'
          }
          onClick={handleChangeLanguage}>
          RU
        </div>
        <div
          className={
            localStorage.getItem('lang') === 'tr' ? 'active_lang' : 'lng'
          }
          onClick={handleChangeLanguage}>
          TR
        </div>
      </div>

      <NavLink to={HOME_ROUTE} className='sidebar_link_wrap'>
        <div className='sidebar_link'>{t('public_routes.main_page')}</div>
      </NavLink>

      {dropdownItemsArr.map(item => (
        <DropdownElement
          name={item.name}
          items={item.items}
          setMenuOpen={props.setIsMenuOpen}
          key={item.name}
        />
      ))}

      <div className='flex'>
        <button
          className='signUpButton'
          onClick={() => {
            setPopupIsVisible(true)
          }}>
          {t('main.sign_up')}
        </button>

        <Link to='/login' className='loginButtonBlock'>
          <div
            onClick={() => {
              props.setIsMenuOpen(false)
            }}
            className='flex'>
            <img src={login} alt='login' />
            <span className='login'>{t('main.login')}</span>
          </div>
        </Link>
      </div>
    </Menu>
  )
}
