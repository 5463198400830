import React, { useState } from 'react'
import s from './Login.module.css'
import logoLogin from '../../assets/newImg/logo_login.png'
import logo from '../../assets/newImg/logo.png'
import { Link, NavLink } from 'react-router-dom'
import { AuthAPI } from '../../Components/Api/Api'
import { authSlice } from '../../Reducers/AuthReducer'
import { useDispatch } from 'react-redux'
import { userSlice } from '../../Reducers/UserReducer'
import { withTranslation } from 'react-i18next'
import { t } from 'i18next'
import MyPopup from '../../utils/MyPopup/MyPopup'
import logoTr from '../../assets/newImg/logo_tr_blue.png'
import Sidebar from '../../Components/sidebar/Sidebar'
import logoTrWhite from '../../assets/newImg/logo_tr_white.png'
import classNames from 'classnames'

const Login = () => {
  let dispatch = useDispatch()

  const { setUserData, setTransactions } = userSlice.actions
  const { setAuthUser, setToken } = authSlice.actions

  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)
  const [pin, setPin] = useState(null)
  const [isPinMode, setIsPinMode] = useState(false)
  const [data, setData] = useState({
    username: '',
    password: '',
  })
  const [popupIsVisible, setPopupIsVisible] = useState(false)

  const confirmPin = async () => {
    const token = localStorage.getItem('token')
    try {
      await AuthAPI.confirmPin(token, pin).then(() => {
        AuthAPI.getUsersData(token).then(function (response) {
          if (response.status === 200) {
            const { data } = response
            const user = {
              companyName: data?.company_name,
              name: data?.client_first_name + ' ' + data?.client_last_name,
              email: data?.email,
            }
            dispatch(setAuthUser(user))
            dispatch(setUserData(data))
          }
        })
        AuthAPI.getUserAccounts(token).then(res => {
          dispatch(setTransactions(res.data))
        })
        setLoading(false)
      })
    } catch (e) {
      const errorMsg =
        e?.response?.data?.message || e?.message || 'Opps, something went wrong'
      setLoading(false)
      setErrorMsg(errorMsg)
    }
  }

  const handleLogin = async () => {
    setLoading(true)
    try {
      const login = await AuthAPI.login(data)
      const token = login?.data?.token
      localStorage.setItem('token', token)
      dispatch(setToken(token))
      await AuthAPI.requestPinCode(token)
      setIsPinMode(true)
    } catch (e) {
      const errorMsg =
        e?.response?.data?.message || e?.message || 'Opps, something went wrong'
      setLoading(false)
      setErrorMsg(errorMsg)
    }
  }

  const getLogo = () => {
    if (localStorage.getItem('lang') === 'tr') {
      return <img src={logoTr} alt='logo' className={s.logo} />
    } else {
      return <img src={logoLogin} alt='logo' className={s.logo} />
    }
  }

  const getTermsAndConditionsLink = () => {
    if (localStorage.getItem('lang') === 'en') {
      return 'TURKISH_INTERNATIONAL_INVESTMENT_BANK_LIMITED_TERMS_AND_CONDITIONS.pdf'
    } else if (localStorage.getItem('lang') === 'tr') {
      return 'TURKIYE_UYB_SARTLAR_VE_KOSULLAR.pdf'
    } else {
      return 'Условия_и_Положения.pdf'
    }
  }

  return (
    <div className={s.login_page_wrapper}>
      <MyPopup visible={popupIsVisible} setVisible={setPopupIsVisible} />
      <div className={s.left}>
        <div>{getLogo()}</div>
        <div className={s.social_links_block}>
          <a
            href='https://www.facebook.com/turkish.international.bank'
            target='_blank'
            className={s.facebook}></a>
          <a
            href='https://www.linkedin.com/company/turkish-international-investment-bank'
            target='_blank'
            className={s.linkedin}></a>
          <a
            href='https://wa.me/+447700306857'
            target='_blank'
            className={s.whatsapp}></a>
          <a href='mailto:info@turkishmailservice.com' className={s.mail}></a>
          <a
            href='https://t.me/+447700306857'
            target='_blank'
            className={s.telegram}></a>
          <a
            href='https://signal.group/#CjQKIClfotfLAbvrDwEvKW9gwt-LeX5LN92W6A1JYttrZ9kVEhCPZ5xtOyGZu5NXGGKzC95q'
            target='_blank'
            className={s.signal}></a>
          <a
            href='https://instagram.com/turkishintinvbank?igshid=YmMyMTA2M2Y='
            target='_blank'
            className={s.insta}></a>
        </div>
      </div>
      <div className={s.right}>
        <div className={s.header_container_mobile}>
          <Sidebar
            pageWrapId={'page-wrap'}
            outerContainerId={'outer-container'}
          />
          <div className={s.mob_logo_wrapper}>
            {localStorage.getItem('lang') === 'en' ? (
              <img src={logo} alt='logo' className={s.logo_mob} />
            ) : (
              <img src={logoTrWhite} alt='logo' className={s.logo_mob} />
            )}
          </div>
          <Link to='/'>
            <div className={s.arr_back_mob}></div>
          </Link>
        </div>

        <div className={s.btn_block}>
          <NavLink to='/'>
            <button className={s.my_btn}>
              <div className={s.arr_back}></div>
              {t('login.back')}
            </button>
          </NavLink>
        </div>

        {isPinMode ? (
          <div className={s.pin_block}>
            <input
              placeholder='Pin'
              type='text'
              required
              className={s.my_input}
              value={pin}
              onChange={e => setPin(e.target.value)}
              style={{ marginBottom: '16px' }}
            />
            <button className={s.login_btn} onClick={confirmPin}>
              {t('login.confirm')}
            </button>
          </div>
        ) : (
          <div className={s.form_block}>
            <form
              onSubmit={e => {
                e.preventDefault()
                handleLogin()
              }}>
              <div className={classNames(s.title, 'ttr-title')}>
                {t('login.login')}
              </div>
              <div className={s.error_msg}>{errorMsg}</div>
              <div className={s.mb}>
                <div className={s.field_name}>{t('login.e_mail')}</div>
                <input
                  type='text'
                  required
                  className={s.my_input}
                  value={data.username}
                  onChange={e => setData({ ...data, username: e.target.value })}
                />
              </div>
              <div className={s.mb}>
                <div className={s.field_name}>{t('login.password')}</div>
                <input
                  type='password'
                  required
                  className={s.my_input}
                  value={data.password}
                  onChange={e => setData({ ...data, password: e.target.value })}
                />
              </div>
              <div className={s.dont_have_account}>
                {t('login.dont_have_account')},{' '}
                <span
                  className={s.bold}
                  onClick={() => {
                    setPopupIsVisible(true)
                  }}>
                  {t('login.sign_up')}
                </span>
              </div>
              <button disabled={loading} className={s.login_btn} type='submit'>
                {t('login.login')}
              </button>
            </form>
          </div>
        )}

        <div className={s.login_footer}>
          <div style={{ marginBottom: '8px' }}>
            <a href={getTermsAndConditionsLink()} target='_blank'>
              <span className={s.underline}>{t('main.terms')}</span>
            </a>
          </div>
          <div>{t('login.all_rights')}</div>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(Login)
