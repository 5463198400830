export const HOME_ROUTE = '/'
export const ABOUT_US_GENERAL_INFORMATION = '/general_information'
export const ABOUT_US_ORGANIZATIONAL_STRUCTURE = '/organizational_structure'
export const ABOUT_US_COMPLIANCE_AND_DOCUMENTS = '/compliance_and_documents'
export const BANKING_UNIVERSAL_PERSONAL_ACCOUNTS = '/universal_accounts'
export const BANKING_ACQUIRING_SERVICES = '/acquiring_services'
export const BANKING_API_GUIDE = '/api_guide'
export const BANKING_FUELING_YOUR_E_COMMERCE = '/fueling_your_e-commerce'
export const BANKING_BANKING_SERVICE = '/banking_service'
export const SAVINGS_ACCOUNT_DEPOSIT = '/deposit'
export const SAVINGS_ACCOUNT_CALCULATE = '/calculate'
export const DEBIT_CARDS_VISA_AND_UNION = '/visa_and_union'
export const DEBIT_CARDS_ACCOUNT_OPENING = '/account_opening'
export const E_MONEY_FOR_BUSINESS_AND_YOURS = '/for_business_and_yours'
export const E_MONEY_FOR_SEPA_SWIFT = '/sepa_swift'
export const CONTACT_US_FORM_SUBMISSIONS = '/form_submissions'
export const CONTACT_US_CONTACT_INFORMATION = '/contact_information'

