import React from 'react'
import MainScreen from '../../Components/mainScreen/MainScreen'
import s from './FuelingYourECommerce.module.css'
import card1 from '../../assets/newImg/eCommerce/card1.png'
import card2 from '../../assets/newImg/eCommerce/card2.png'
import card3 from '../../assets/newImg/eCommerce/card3.png'
import card4 from '../../assets/newImg/eCommerce/card4.png'
import MyBtn from '../../Components/myBtn/MyBtn'
import { DEBIT_CARDS_ACCOUNT_OPENING } from '../../router/Constants'
import { useHistory } from 'react-router-dom'
import { t } from 'i18next'

const FuelingYourECommerce = () => {
  const redirect = useHistory()

  return (
    <div>
      <MainScreen
        title={t('public_routes.fueling_your_e_commerce')}
        titleTwo={''}
        pageName={t('main.banking')}
      />

      <div className={s.container}>
        <div className={s.descriptionTwoLine}>
          <div className={s.lineShort}></div>
          <div className={s.textBlockTwoLine}>
            <div>{t('fueling_your_e_commerce.title1')}</div>
            <div>{t('fueling_your_e_commerce.subtitle1')}</div>
          </div>
          <div className={s.lineShort}></div>
        </div>

        <div className={s.cardsBlock}>
          <div className={s.card}>
            <img className={s.cardBg} src={card1} alt='' />
            <div className={s.cardTextBlock}>
              <div className={s.cardText}>
                {t('fueling_your_e_commerce.card1')}
              </div>
            </div>
          </div>

          <div className={s.card}>
            <img className={s.cardBg} src={card2} alt='' />
            <div className={s.cardTextBlock}>
              <div className={s.cardText}>
                {t('fueling_your_e_commerce.card2')}
              </div>
            </div>
          </div>

          <div className={s.card}>
            <img className={s.cardBg} src={card3} alt='' />
            <div className={s.cardTextBlock}>
              <div className={s.cardText}>
                {t('fueling_your_e_commerce.card3')}
              </div>
            </div>
          </div>

          <div className={s.card}>
            <img className={s.cardBg} src={card4} alt='' />
            <div className={s.cardTextBlock}>
              <div className={s.cardTextSmall}>
                {t('fueling_your_e_commerce.card4')}
              </div>
            </div>
          </div>
        </div>

        <div className={s.descriptionBlock}>
          <div className={s.textBlock}>
            {t('fueling_your_e_commerce.title2')}
          </div>
          <div className={s.line}></div>
          <div className={s.textBlock}>
            {t('fueling_your_e_commerce.subtitle2')}
          </div>
        </div>

        <div className={s.btnBlock}>
          <MyBtn
            title={t('main.open_account')}
            onClick={() => {
              redirect.push(DEBIT_CARDS_ACCOUNT_OPENING)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default FuelingYourECommerce
