import React, { useState } from 'react'
import MainScreen from '../../Components/mainScreen/MainScreen'
import s from './FormSubmissions.module.css'
import MyPopupSuccess from '../../utils/MyPopupSuccess/MyPopupSuccess'
import MyPopupError from '../../utils/MyPopupError/MyPopupError'
import { t } from 'i18next'
import PhonecodesInput from '../../Components/phonecodesInput/PhonecodesInput'

const FormSubmissions = () => {
  const [data, setData] = useState({
    firstName: '',
    message: '',
    phone: '',
    email: '',
    nameOfCountry: '',
  })

  const [popupVisible, setPopupVisible] = useState(false)
  const [popupErrorVisible, setPopupErrorVisible] = useState(false)

  const dataForSend = {
    project: 'turkey',
    subject: 'Hello!',
    data: data,
  }

  const sendMessage = () => {
    const headers = {
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${token}`,
    }

    return fetch('https://assembly.tehnik.tech/api/send_form/from', {
      method: 'POST',
      headers,
      body: JSON.stringify(dataForSend),
    })
      .then(() => {
        setPopupVisible(true)
      })
      .catch(() => {
        setPopupErrorVisible(true)
      })
  }

  return (
    <div>
      <MyPopupSuccess visible={popupVisible} setVisible={setPopupVisible} />
      <MyPopupError
        visible={popupErrorVisible}
        setVisible={setPopupErrorVisible}
      />
      <MainScreen
        title={t('public_routes.form_submissions')}
        pageName={t('main.contact_us')}
      />

      <div className={s.wrapper}>
        <div className={s.container}>
          <div className={s.title}>{t('form_submissions.people_trust_us')}</div>
          <div>
            <div className={s.inputs_block}>
              <div className={s.input_name_block}>
                <div className={s.input_name}>{t('form_submissions.name')}</div>
                <input
                  className={s.my_input}
                  type='text'
                  value={data.firstName}
                  onChange={e => {
                    setData({ ...data, firstName: e.currentTarget.value })
                  }}
                />
              </div>
              <PhonecodesInput data={data} setData={setData} />
              <div>
                <div className={s.input_name}>
                  {t('form_submissions.email')}
                </div>
                <input
                  className={s.my_input}
                  type='text'
                  value={data.email}
                  onChange={e => {
                    setData({ ...data, email: e.currentTarget.value })
                  }}
                />
              </div>
            </div>
            <div>
              <div className={s.input_name}>
                {t('form_submissions.message')}
              </div>
              <textarea
                className={s.my_textarea}
                value={data.message}
                onChange={e => {
                  setData({ ...data, message: e.currentTarget.value })
                }}
              />
            </div>
            <button className={s.my_btn} onClick={sendMessage}>
              {t('form_submissions.send')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormSubmissions
