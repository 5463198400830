import React, {useState} from 'react';
import s from './MyPopup.module.css'
import closePopup from "../../assets/newImg/Close.png";
import {API} from "../api/api";
import MyPopupSuccess from "../MyPopupSuccess/MyPopupSuccess";
import {withTranslation} from "react-i18next";
import {t} from "i18next";
import MyPopupError from "../MyPopupError/MyPopupError";

const MyPopup = ({visible, setVisible}) => {

    const rootClasses = [s.myModal]

    if (visible) {
        rootClasses.push(s.active)
    }


    const [popupSuccessVisible, setPopupSuccessVisible] = useState(false)
    const [error, setError] = useState("")
    const [popupErrorVisible, setPopupErrorVisible] = useState(false)
    // const [selectedFile, setSelectedFile] = useState(false);
    const [sendUserData, setSendUserData] = useState({
        email: "",
        phone: "",
        name: "",
    })

    const sendMessage = async () => {

        if (sendUserData.email.search("^[A-z0-9]{1,15}.?[A-z0-9]{1,15}@[a-z]{2,20}.[a-z]{2,4}$") === -1) {
            const error = t('main.valid_email')
            setError(error)
        } else if (sendUserData.name.length <= 0 || sendUserData.email.length <= 0 || sendUserData.phone.length <= 0){
            const error = t('main.all_fields')
            setError(error)
        } else {
            setError("")
            await API.sendUserData(sendUserData)
                .then(() => {
                    setPopupSuccessVisible(true)
                }).catch(() => {
                    setPopupErrorVisible(true)
                });
        }
    }

    // const changeHandler = async (event) => {
    //     let reader = new FileReader();
    //     reader.readAsDataURL(event.target.files[0]);
    //     reader.onload = function () {
    //         setSelectedFile(true)
    //         setSendUserData({...sendUserData, file_pdf: reader.result})
    //     };
    //     reader.onerror = function (error) {
    //         console.log('Error: ', error);
    //     };
    // };

    return (
        <div className={rootClasses.join(' ')} onClick={() => setVisible(false)}>
            <div className={s.myModalContent} onClick={(e) => e.stopPropagation()}>
                <MyPopupSuccess visible={popupSuccessVisible} setVisible={setPopupSuccessVisible}/>
                <MyPopupError visible={popupErrorVisible} setVisible={setPopupErrorVisible}/>
                <div className={s.close_popup}>
                    <img src={closePopup} alt="close" onClick={() => setVisible(false)}/>
                </div>

                <div className={s.popup_title}>
                    {t('popup.title_opening')}
                </div>

                {/*<div className={s.step_one_block}>*/}
                {/*    <div className={s.step_title}>01. {t('popup.download')}</div>*/}
                {/*    <div className={s.download_pdf}>*/}
                {/*        <img src={pdf_img} alt=""/>*/}


                {/*        {localStorage.getItem('lang') === 'en'*/}
                {/*            ? <a href="Account_Opening_Form_For_Individuals.pdf" download>*/}
                {/*                <span className={s.pdf_name}>{t('popup.personal')}</span>*/}
                {/*                <img className={s.download_img} src={download} alt=""/>*/}
                {/*            </a>*/}

                {/*            : <a href="Kişisel_Hesap_Açma_Formu.pdf" download>*/}
                {/*                <span className={s.pdf_name}>{t('popup.personal')}</span>*/}
                {/*                <img className={s.download_img} src={download} alt=""/>*/}
                {/*            </a>*/}
                {/*        }*/}

                {/*    </div>*/}

                {/*    <div className={s.download_pdf}>*/}
                {/*        <img src={pdf_img} alt=""/>*/}


                {/*        {localStorage.getItem('lang') === 'en'*/}
                {/*            ? <a href="Account_Opening_Form_For_Corporate.pdf" download>*/}
                {/*                <span className={s.pdf_name}>{t('popup.corporate')}</span>*/}
                {/*                <img className={s.download_img} src={download} alt=""/>*/}
                {/*            </a>*/}

                {/*            : <a href="Kurumsal_Hesap_Açm_Formu.pdf" download>*/}
                {/*                <span className={s.pdf_name}>{t('popup.corporate')}</span>*/}
                {/*                <img className={s.download_img} src={download} alt=""/>*/}
                {/*            </a>*/}
                {/*        }*/}

                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className={s.step_one_block}>*/}
                {/*    <div className={s.step_title}>02. {t('popup.upload')}</div>*/}
                {/*    <div className={s.download_pdf}>*/}
                {/*        <button className={s.my_btn}>*/}
                {/*            {t('popup.add')}*/}
                {/*            <input type="file"*/}
                {/*                   name="file"*/}
                {/*                   placeholder={'ADD'}*/}
                {/*                   onChange={changeHandler}*/}
                {/*                   className={s.upload_pdf}*/}
                {/*            />*/}
                {/*        </button>*/}
                {/*        {!!selectedFile && <div className={s.done}>{t('popup.success')}</div>}*/}
                {/*    </div>*/}

                {/*</div>*/}
                {/*<div className={s.step_one_block}>*/}
                {/*    <div className={s.step_title}>03. {t('popup.contact_info')}</div>*/}
                {/*    <div className={s.contact_info_block}>*/}
                {/*        <div className={s.phone_block}>*/}
                {/*            <div className={s.input_title}>{t('popup.phone')}</div>*/}
                {/*            <input type="text"*/}
                {/*                   className={s.my_input}*/}
                {/*                   onChange={e => setSendUserData({...sendUserData, phone: e.currentTarget.value})}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*        <div>*/}
                {/*            <div className={s.input_title}>{t('popup.email')}</div>*/}
                {/*            <input type="text"*/}
                {/*                   className={s.my_input}*/}
                {/*                   onChange={e => setSendUserData({...sendUserData, email: e.currentTarget.value})}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}





                <div className={s.flex}>
                    <div className={s.popup_subtitle}>
                        {t('popup.subtitle')}
                    </div>
                </div>
                <div className={s.form}>
                    <div className={s.input_block}>
                        <span>{t('popup.name')}</span>
                        <input type="text"
                               value={sendUserData.name}
                               onChange={(e) => {
                                   setSendUserData({...sendUserData, name: e.currentTarget.value})
                               }}
                        />
                    </div>
                    <div className={s.input_block}>
                        <span>{t('popup.phone')}</span>
                        <input type="text"
                               value={sendUserData.phone}
                               onChange={(e) => {
                                   setSendUserData({...sendUserData, phone: e.currentTarget.value})
                               }}
                        />
                    </div>
                    <div className={s.input_block}>
                        <span>{t('popup.email')}</span>
                        <input type="text"
                               value={sendUserData.email}
                               onChange={(e) => {
                                   setSendUserData({...sendUserData, email: e.currentTarget.value})
                               }}
                        />
                    </div>
                </div>

                <div className={s.flex}>
                    <button className={s.my_btn} onClick={sendMessage}>{t('popup.send')}</button>
                </div>

                <div className={s.error}>{error}</div>

            </div>
        </div>
    );
};

export default withTranslation()(MyPopup)
