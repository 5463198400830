import React, { useState } from 'react'
import './index.css'
import s from './Slider.module.css'
import prevItem from '../../assets/newImg/arrow_left.png'
import nextItem from '../../assets/newImg/arrow_right.png'
import logo from '../../assets/newImg/logo_slider.png'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import townImage from '../../assets/newImg/town_image.png'
import MyBtn from '../myBtn/MyBtn'
import { DEBIT_CARDS_ACCOUNT_OPENING } from '../../router/Constants'
import { useHistory } from 'react-router-dom'
import { t } from 'i18next'

const MySlider = () => {
  const cards = [
    {
      number: 1,
      title: t('main.slider.TIIB'),
      text: t('main.slider.experience'),
      bgURL: '/newImg/main_screen_1.png',
    },
    {
      number: 2,
      title: t('main.banking'),
      text: t('main.slider.TIIB'),
      bgURL: '/newImg/main_screen_2.png',
    },
    {
      number: 3,
      title: t('main.savings_account'),
      text: t('main.slider.experience'),
      bgURL: '/newImg/main_screen_3.png',
    },
    {
      number: 4,
      title: t('main.debit_cards'),
      text: t('main.slider.experience'),
      bgURL: '/newImg/main_screen_4.png',
    },
    {
      number: 5,
      title: t('main.e_money'),
      text: t('main.slider.experience'),
      bgURL: '/newImg/main_screen_5.png',
    },
  ]

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const [sliderRef, setSliderRef] = useState(null)
  const redirect = useHistory()

  return (
    <div className={s.slider_wrapper}>
      <Slider ref={setSliderRef} {...settings} className={s.my_slider}>
        {cards.map(card => (
          <div key={card.number} className={s.active}>
            <div className={s.card_content}>
              <img className={s.logo} src={logo} alt='' />
              <div className={s.card_desc}>{card.text}</div>
              <div>
                <img className={s.town_image} src={townImage} alt='' />
              </div>
              <div className={s.card_title}>{card.title}</div>
              <div className={s.btn_wrapper}>
                <MyBtn
                  title={t('main.sign_up')}
                  onClick={() => {
                    redirect.push(DEBIT_CARDS_ACCOUNT_OPENING)
                  }}
                />
              </div>

              <div onClick={sliderRef?.slickPrev}>
                <img src={prevItem} alt='' className={s.arrow_prev} />
              </div>
              <div onClick={sliderRef?.slickNext}>
                <img src={nextItem} alt='' className={s.arrow_next} />
              </div>
            </div>

            <div className={s.bg}>
              <img src={card.bgURL} alt='' />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default MySlider
