import React from 'react'
import MainScreen from '../../Components/mainScreen/MainScreen'
import s from './AccountDeposit.module.css'
import bg from '../../assets/newImg/deposit/depositBg.png'
import bgMobile from '../../assets/newImg/deposit/deposit_bg_mobile.svg'
import MyBtn from '../../Components/myBtn/MyBtn'
import { DEBIT_CARDS_ACCOUNT_OPENING } from '../../router/Constants'
import { useHistory } from 'react-router-dom'
import { t } from 'i18next'

const AccountDeposit = () => {
  const redirect = useHistory()

  return (
    <div>
      <MainScreen
        title={t('public_routes.deposit_with_tiib')}
        pageName={t('main.savings_account')}
      />
      <div className={s.colorWrapper}>
        <div className={s.contentBlock}>
          <img className={s.bgImg} src={bg} alt='' />
          <img className={s.bgImgMobile} src={bgMobile} alt='' />
          <div className={s.textBlock}>
            <div className={s.text}>{t('deposit_with_tiib.title')}</div>
            <div className={s.text}>{t('deposit_with_tiib.subtitle')}</div>
            <div className={s.btnBlock}>
              <MyBtn
                title={t('main.open_account')}
                onClick={() => {
                  redirect.push(DEBIT_CARDS_ACCOUNT_OPENING)
                }}
              />
            </div>
          </div>
          <div className={s.textBlockMobile}>
            <div className={s.text}>{t('deposit_with_tiib.title')}</div>
          </div>
        </div>
        <div className={s.textBottomMobile}>
          {t('deposit_with_tiib.subtitle')}
        </div>
        <div className={s.btnBlockMobile}>
          <MyBtn
            title={t('main.open_account')}
            onClick={() => {
              redirect.push(DEBIT_CARDS_ACCOUNT_OPENING)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default AccountDeposit
