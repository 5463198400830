import React from 'react'
import MainScreen from '../../Components/mainScreen/MainScreen'
import bg from '../../assets/newImg/bankingService/banking_service.png'
import dot from '../../assets/newImg/bankingService/dot.png'
import s from './BankingService.module.css'
import MyBtn from '../../Components/myBtn/MyBtn'
import { DEBIT_CARDS_ACCOUNT_OPENING } from '../../router/Constants'
import { useHistory } from 'react-router-dom'
import { t } from 'i18next'

const BankingService = () => {
  const redirect = useHistory()

  return (
    <div>
      <MainScreen
        title={t('public_routes.banking_service')}
        pageName={t('main.banking')}
      />

      <div className={s.wrapper}>
        <div>
          <div className={s.title}>{t('banking_service.title')}</div>
          <div className={s.btnWrapper}>
            <MyBtn
              title={t('main.open_account')}
              onClick={() => {
                redirect.push(DEBIT_CARDS_ACCOUNT_OPENING)
              }}
            />
          </div>
        </div>
        <div className={s.listWrapper}>
          <img className={s.bg} src={bg} alt='' />
          <div className={s.list}>
            <div className={s.listItem}>
              <img src={dot} alt='' />
              <span className={s.text}>{t('banking_service.line1')}</span>
            </div>
            <div className={s.listItem}>
              <img src={dot} alt='' />
              <span className={s.text}>{t('banking_service.line2')}</span>
            </div>
            <div className={s.listItem}>
              <img src={dot} alt='' />
              <span className={s.text}>{t('banking_service.line3')}</span>
            </div>
            <div className={s.listItem}>
              <img src={dot} alt='' />
              <span className={s.text}>{t('banking_service.line4')}</span>
            </div>
            <div className={s.listItem}>
              <img src={dot} alt='' />
              <span className={s.text}>{t('banking_service.line5')}</span>
            </div>
            <div className={s.listItem}>
              <img src={dot} alt='' />
              <span className={s.text}>{t('banking_service.line6')}</span>
            </div>
            <div className={s.listItem}>
              <img src={dot} alt='' />
              <span className={s.text}>{t('banking_service.line7')}</span>
            </div>
            <div className={s.listItem}>
              <img src={dot} alt='' />
              <span className={s.text}>{t('banking_service.line8')}</span>
            </div>
            <div className={s.listItem}>
              <img src={dot} alt='' />
              <span className={s.text}>{t('banking_service.line9')}</span>
            </div>
            <div className={s.listItem}>
              <img src={dot} alt='' />
              <span className={s.text}>{t('banking_service.line10')}</span>
            </div>
          </div>
        </div>

        <div className={s.btnWrapperMobile}>
          <MyBtn
            title={t('main.open_account')}
            onClick={() => {
              redirect.push(DEBIT_CARDS_ACCOUNT_OPENING)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default BankingService
