import { t } from 'i18next'
import {
  ABOUT_US_COMPLIANCE_AND_DOCUMENTS,
  ABOUT_US_GENERAL_INFORMATION,
  ABOUT_US_ORGANIZATIONAL_STRUCTURE,
  BANKING_ACQUIRING_SERVICES,
  BANKING_API_GUIDE,
  BANKING_BANKING_SERVICE,
  BANKING_FUELING_YOUR_E_COMMERCE,
  BANKING_UNIVERSAL_PERSONAL_ACCOUNTS,
  CONTACT_US_CONTACT_INFORMATION,
  CONTACT_US_FORM_SUBMISSIONS,
  DEBIT_CARDS_ACCOUNT_OPENING,
  DEBIT_CARDS_VISA_AND_UNION,
  E_MONEY_FOR_BUSINESS_AND_YOURS,
  E_MONEY_FOR_SEPA_SWIFT,
  HOME_ROUTE,
  SAVINGS_ACCOUNT_CALCULATE,
  SAVINGS_ACCOUNT_DEPOSIT,
} from './Constants'
import Home from '../Pages/home/Home'
import GeneralInformation from '../Pages/generalInformation/GeneralInformation'
import OrganizationalStructure from '../Pages/organizationalStructure/OrganizationalStructure'
import ComplianceAndDocuments from '../Pages/complianceAndDocuments/ComplianceAndDocuments'
import UniversalPersonalAccounts from '../Pages/universalPersonalAccounts/UniversalPersonalAccounts'
import AcquiringServices from '../Pages/acquiringServices/AcquiringServices'
import FuelingYourECommerce from '../Pages/fuelingYourECommerce/FuelingYourECommerce'
import BankingService from '../Pages/bankingService/BankingService'
import AccountDeposit from '../Pages/accountDeposit/AccountDeposit'
import CalculateAmountOfInterest from '../Pages/calculateAmountOfInterest/CalculateAmountOfInterest'
import VisaAndUnion from '../Pages/visaAndUnion/VisaAndUnion'
import AccountOpening from '../Pages/accountOpening/AccountOpening'
import SepaSwift from '../Pages/sepaSwift/SepaSwift'
import FormSubmissions from '../Pages/formSubmissions/FormSubmissions'
import ContactInformation from '../Pages/contactInformation/ContactInformation'
import BusinessAndYours from '../Pages/businessAndYours/BusinessAndYours'
import ApiGuide from '../Pages/apiGuide/ApiGuide'

export const homeRoute = [
  {
    path: HOME_ROUTE,
    title: t('public_routes.main_page'),
    component: Home,
  },
]

export const aboutUsRoutes = [
  {
    path: ABOUT_US_GENERAL_INFORMATION,
    title: t('public_routes.general_information'),
    component: GeneralInformation,
  },
  {
    path: ABOUT_US_ORGANIZATIONAL_STRUCTURE,
    title: t('public_routes.organizational_structure'),
    component: OrganizationalStructure,
  },
  {
    path: ABOUT_US_COMPLIANCE_AND_DOCUMENTS,
    title: t('public_routes.compliance_and_documents'),
    component: ComplianceAndDocuments,
  },
]

export const bankingRoutes = [
  {
    path: BANKING_UNIVERSAL_PERSONAL_ACCOUNTS,
    title: t('public_routes.universal_personal_accounts'),
    component: UniversalPersonalAccounts,
  },
  {
    path: BANKING_ACQUIRING_SERVICES,
    title: t('public_routes.acquiring_services'),
    component: AcquiringServices,
  },
  {
    path: BANKING_API_GUIDE,
    title: t('public_routes.api_guide'),
    component: ApiGuide,
  },
  {
    path: BANKING_FUELING_YOUR_E_COMMERCE,
    title: t('public_routes.fueling_your_e_commerce'),
    component: FuelingYourECommerce,
  },
  {
    path: BANKING_BANKING_SERVICE,
    title: t('public_routes.banking_service'),
    component: BankingService,
  },
]

export const savingsAccountRoutes = [
  {
    path: SAVINGS_ACCOUNT_DEPOSIT,
    title: t('public_routes.deposit_with_tiib'),
    component: AccountDeposit,
  },
  {
    path: SAVINGS_ACCOUNT_CALCULATE,
    title: t('public_routes.calculate_amount_of_interest'),
    component: CalculateAmountOfInterest,
  },
]

export const debitCardsRoutes = [
  {
    path: DEBIT_CARDS_VISA_AND_UNION,
    title: t('public_routes.visa_and_union_pay_debit_cards'),
    component: VisaAndUnion,
  },
  {
    path: DEBIT_CARDS_ACCOUNT_OPENING,
    title: t('public_routes.account_opening'),
    component: AccountOpening,
  },
]

export const eMoneyRoutes = [
  {
    path: E_MONEY_FOR_BUSINESS_AND_YOURS,
    title: t('public_routes.for_business_and_yours'),
    component: BusinessAndYours,
  },
  {
    path: E_MONEY_FOR_SEPA_SWIFT,
    title: t('public_routes.SEPA_SWIFT'),
    component: SepaSwift,
  },
]

export const contactUsRoutes = [
  {
    path: CONTACT_US_FORM_SUBMISSIONS,
    title: t('public_routes.form_submissions'),
    component: FormSubmissions,
  },
  {
    path: CONTACT_US_CONTACT_INFORMATION,
    title: t('public_routes.contact_information'),
    component: ContactInformation,
  },
]

export const allRoutes = () => {
  return [
    ...contactUsRoutes,
    ...eMoneyRoutes,
    ...debitCardsRoutes,
    ...homeRoute,
    ...aboutUsRoutes,
    ...bankingRoutes,
    ...savingsAccountRoutes,
  ]
}
