import React from 'react'
import s from './ContactInformation.module.css'
import MainScreen from '../../Components/mainScreen/MainScreen'
import bg from '../../assets/newImg/contactInformation/content_bg.svg'
import { t } from 'i18next'
import map from '../../assets/newImg/contactInformation/Map.png'
import mail from '../../assets/newImg/contactInformation/Mail.png'
import phone from '../../assets/newImg/contactInformation/Phone.png'
import linkedin from '../../assets/newImg/contactInformation/LinkedIN.png'
import facebook from '../../assets/newImg/contactInformation/Facebook.png'
import whatsapp from '../../assets/newImg/contactInformation/WhatsApp.png'
import telegram from '../../assets/newImg/contactInformation/Telegram.png'
import signal from '../../assets/newImg/contactInformation/Signal.png'
import insta from '../../assets/newImg/contactInformation/Insta.png'

const ContactInformation = () => {
  return (
    <div className={s.wrapper}>
      <MainScreen
        title={t('public_routes.contact_information')}
        pageName={t('main.contact_us')}
      />

      <div className={s.container}>
        <div className={s.descriptionTwoLine}>
          <div className={s.lineShort}></div>
          <div className={s.textBlockTwoLine}>
            <div>{t('contact_information.title1')}</div>
          </div>
          <div className={s.lineShort}></div>
        </div>
      </div>

      <div className={s.colorWrapper}>
        <div className={s.contentBlock}>
          <img className={s.bgImg} src={bg} alt='' />
          <div className={s.topText}>
            <div className={s.welcome}>{t('contact_information.reach_us')}</div>
            <div className={s.contacts}>
              <div className={s.img}>
                <img src={map} alt='' />
              </div>
              <div>5C4V+V9M, Tifariti, SADR</div>
            </div>
            <div className={s.contacts}>
              <div className={s.img}>
                <img src={mail} alt='' />
              </div>
              <div>info@turkibu.com</div> {/*info@turkishibumail.com*/}
            </div>
            <div className={s.contacts}>
              <div className={s.img}>
                <img src={phone} alt='' />
              </div>
              <div>+44 2045774192</div>
            </div>
          </div>
          {/*<div className={s.bottomText}>*/}
          {/*    <div className={s.cyprus_title}>*/}
          {/*        {t('contact.cyprus_title')}*/}
          {/*    </div>*/}
          {/*    <div className={s.contacts}>*/}
          {/*        <div className={s.img}><img src={map} alt=""/></div>*/}
          {/*        <div>*/}
          {/*            {t('contact.cyprus_address_1')} <br/>*/}
          {/*            {t('contact.cyprus_address_2')} <br/>*/}
          {/*            {t('contact.cyprus_address_3')} <br/>*/}
          {/*        </div>*/}
          {/*    </div>*/}
          {/*    <div className={s.contacts} style={{marginBottom: '20px'}}>*/}
          {/*        <div className={s.img}><img src={phone} alt=""/></div>*/}
          {/*        <div>+35722030061</div>*/}
          {/*    </div>*/}
          {/*</div>*/}
        </div>
      </div>

      <div className={s.container}>
        <div className={s.descriptionBlock}>
          <div className={s.textBlock}>{t('contact_information.title2')}</div>
          <div className={s.line}></div>
          <div className={s.listBlock}>
            <div className={s.pointBlock}>
              <a
                href='https://www.linkedin.com/company/turkish-international-investment-bank'
                target='_blank'>
                <div className={s.social_link}>
                  <img className={s.img} src={linkedin} alt='' />
                  <div className={s.social_link_text}>LinkedIn</div>
                </div>
              </a>
            </div>
            <div className={s.pointBlock}>
              <a
                href='https://www.facebook.com/turkish.international.bank'
                target='_blank'>
                <div className={s.social_link}>
                  <img className={s.img} src={facebook} alt='' />
                  <div className={s.social_link_text}>Facebook</div>
                </div>
              </a>
            </div>
            <div className={s.pointBlock}>
              <a href='https://wa.me/+447700306857' target='_blank'>
                <div className={s.social_link}>
                  <img className={s.img} src={whatsapp} alt='' />
                  <div className={s.social_link_text}>WhatsApp</div>
                </div>
              </a>
            </div>
            <div className={s.pointBlock}>
              <a href='https://t.me/+447700306857' target='_blank'>
                <div className={s.social_link}>
                  <img className={s.img} src={telegram} alt='' />
                  <div className={s.social_link_text}>Telegram</div>
                </div>
              </a>
            </div>
            <div className={s.pointBlock}>
              <a
                href='https://signal.group/#CjQKIClfotfLAbvrDwEvKW9gwt-LeX5LN92W6A1JYttrZ9kVEhCPZ5xtOyGZu5NXGGKzC95q'
                target='_blank'>
                <div className={s.social_link}>
                  <img className={s.img} src={signal} alt='' />
                  <div className={s.social_link_text}>Signal</div>
                </div>
              </a>
            </div>
            <div className={s.pointBlock}>
              <a href='#' target='_blank'>
                <div className={s.social_link}>
                  <img className={s.img} src={insta} alt='' />
                  <div className={s.social_link_text}>Instagram</div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactInformation
