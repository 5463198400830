import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import s from "./Footer.module.css";
import logo from "../../assets/newImg/logoBlue.png";
import login from "../../assets/newImg/Log_in.png";
import { t } from "i18next";
import { withTranslation } from "react-i18next";
import {
    ABOUT_US_COMPLIANCE_AND_DOCUMENTS,
    ABOUT_US_GENERAL_INFORMATION,
    ABOUT_US_ORGANIZATIONAL_STRUCTURE,
    BANKING_ACQUIRING_SERVICES,
    BANKING_BANKING_SERVICE,
    BANKING_FUELING_YOUR_E_COMMERCE,
    BANKING_UNIVERSAL_PERSONAL_ACCOUNTS,
    CONTACT_US_CONTACT_INFORMATION,
    CONTACT_US_FORM_SUBMISSIONS,
    DEBIT_CARDS_ACCOUNT_OPENING,
    DEBIT_CARDS_VISA_AND_UNION,
    E_MONEY_FOR_BUSINESS_AND_YOURS,
    E_MONEY_FOR_SEPA_SWIFT,
    HOME_ROUTE,
    SAVINGS_ACCOUNT_CALCULATE,
    SAVINGS_ACCOUNT_DEPOSIT,
} from "../../router/Constants";
import classnames from "classnames";
import i18n from "../../i18n";

const Footer = () => {
    const [popupIsVisible, setPopupIsVisible] = useState(false);

    const location = useLocation();

    const isLogin = () => {
        return location.pathname === "/login";
    };

    const isSignup = () => {
        return location.pathname === "/signup";
    };

    // const getLogo = () => {
    //     if (localStorage.getItem('lang') === 'tr') {
    //         return <img src={logoTr} alt="logo" className={s.logoBlock}/>
    //     } else {
    //         return <img src={logo} alt="logo" className={s.logoBlock}/>
    //     }
    // }
    //
    const getTermsAndConditionsLink = () => {
        if (localStorage.getItem("lang") === "en") {
            return "TURKISH_INTERNATIONAL_INVESTMENT_BANK_LIMITED_TERMS_AND_CONDITIONS.pdf";
        } else if (localStorage.getItem("lang") === "tr") {
            return "TURKIYE_UYB_SARTLAR_VE_KOSULLAR.pdf";
        } else {
            return "Условия_и_Положения.pdf";
        }
    };

    const handleChangeLanguage = e => {
        const lang = e.target.textContent.toLowerCase();
        i18n.changeLanguage(lang);
        localStorage.setItem("lang", lang);
        window.location.reload();
    };

    return (
        <div>
            {isLogin() || isSignup() ? (
                <div></div>
            ) : (
                <div className={s.footerWrapper}>
                    <div className={s.footerContainer}>
                        <div className={s.row}>
                            <div>
                                <img src={logo} alt='' />
                            </div>
                            <div className={s.header_block}>
                                <Link to='/login'>
                                    <div className={s.loginButtonBlock}>
                                        <img src={login} alt='login' />
                                        <span className={s.login}>{t("main.login")}</span>
                                    </div>
                                </Link>
                                <div>
                                    <button
                                        className={s.signUpButton}
                                        onClick={() => {
                                            setPopupIsVisible(true);
                                        }}>
                                        {t("main.sign_up")}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className={s.footerLine}></div>
                        <div className={s.linksWrapper}>
                            <div>
                                <NavLink to={HOME_ROUTE} className={classnames(s.mainPageLink)}>
                                    <div className={s.homePageLinkBlock}>{t("public_routes.main_page")}</div>
                                </NavLink>
                                <div className={s.linksBlock}>
                                    <div className={classnames(s.linksTitle)}>{t("main.about_us")}</div>
                                    <div className={s.column}>
                                        <NavLink to={ABOUT_US_GENERAL_INFORMATION} className={classnames(s.links)}>
                                            {t("public_routes.general_information")}
                                        </NavLink>
                                        <NavLink to={ABOUT_US_ORGANIZATIONAL_STRUCTURE} className={classnames(s.links)}>
                                            {t("public_routes.organizational_structure")}
                                        </NavLink>
                                        <NavLink to={ABOUT_US_COMPLIANCE_AND_DOCUMENTS} className={classnames(s.links)}>
                                            {t("public_routes.compliance_and_documents")}
                                        </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className={s.linksBlock}>
                                    <div className={classnames(s.linksTitle)}>{t("main.banking")}</div>
                                    <div className={s.column}>
                                        <NavLink to={BANKING_UNIVERSAL_PERSONAL_ACCOUNTS} className={classnames(s.links)}>
                                            {t("public_routes.universal_personal_accounts")}
                                        </NavLink>
                                        <NavLink to={BANKING_ACQUIRING_SERVICES} className={classnames(s.links)}>
                                            {t("public_routes.acquiring_services")}
                                        </NavLink>
                                        <NavLink to={BANKING_FUELING_YOUR_E_COMMERCE} className={classnames(s.links)}>
                                            {t("public_routes.fueling_your_e_commerce")}
                                        </NavLink>
                                        <NavLink to={BANKING_BANKING_SERVICE} className={classnames(s.links)}>
                                            {t("public_routes.banking_service")}
                                        </NavLink>
                                        <a
                                            href='https://alzafx.com'
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            className={s.links}>
                                            FX Trade
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className={s.linksBlock}>
                                    <div className={classnames(s.linksTitle)}></div>
                                    <div className={s.column}>
                                        <NavLink to={SAVINGS_ACCOUNT_DEPOSIT} className={classnames(s.links)}>
                                            {t("public_routes.deposit_with_tiib")}
                                        </NavLink>
                                        <NavLink to={SAVINGS_ACCOUNT_CALCULATE} className={classnames(s.links)}>
                                            {t("public_routes.calculate_amount_of_interest")}
                                        </NavLink>
                                    </div>
                                </div>
                                <div className={s.linksBlock}>
                                    <div className={classnames(s.linksTitle)}>{t("main.debit_cards")}</div>
                                    <div className={s.column}>
                                        <NavLink to={DEBIT_CARDS_VISA_AND_UNION} className={classnames(s.links)}>
                                            {t("public_routes.visa_and_union_pay_debit_cards")}
                                        </NavLink>
                                        <NavLink to={DEBIT_CARDS_ACCOUNT_OPENING} className={classnames(s.links)}>
                                            {t("main.sign_up")}
                                        </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className={s.linksBlock}>
                                    <div className={classnames(s.linksTitle)}>{t("main.e_money")}</div>
                                    <div className={s.column}>
                                        <NavLink to={E_MONEY_FOR_BUSINESS_AND_YOURS} className={classnames(s.links)}>
                                            {t("public_routes.for_business_and_yours")}
                                        </NavLink>
                                        <NavLink to={E_MONEY_FOR_SEPA_SWIFT} className={classnames(s.links)}>
                                            {t("public_routes.SEPA_SWIFT")}
                                        </NavLink>
                                    </div>
                                </div>
                                <div className={s.linksBlock}>
                                    <div className={classnames(s.linksTitle)}>{t("main.contact_us")}</div>
                                    <div className={s.column}>
                                        <NavLink to={CONTACT_US_FORM_SUBMISSIONS} className={classnames(s.links)}>
                                            {t("public_routes.form_submissions")}
                                        </NavLink>
                                        <NavLink to={CONTACT_US_CONTACT_INFORMATION} className={classnames(s.links)}>
                                            {t("public_routes.contact_information")}
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={s.footerLine}></div>

                        <div className={s.footerCopyright}>
                            <div className={classnames(s.copyrightText)}>
                                <span>
                                    © {new Date().getFullYear()} {t("main.all_rights")}
                                </span>
                                {/*<span className={s.downloadLink}>Privacy Statement</span>*/}
                                {/*<span> and </span>*/}
                                {/*<span className={s.downloadLink}>Terms of Use.</span>*/}
                            </div>
                            <div className={s.flex}>
                                <div className={s.social_links_block}>
                                    <a
                                        href='https://www.facebook.com/turkish.international.bank'
                                        target='_blank'
                                        className={s.facebook}></a>
                                    <a
                                        href='https://www.linkedin.com/company/turkish-international-investment-bank'
                                        target='_blank'
                                        className={s.linkedin}></a>
                                    <a href='https://wa.me/+447700306857' target='_blank' className={s.whatsapp}></a>
                                    <a href='mailto:info@turkibu.com' className={s.mail}></a>
                                    <a href='https://t.me/+447700306857' target='_blank' className={s.telegram}></a>
                                    <a
                                        href='https://signal.group/#CjQKIClfotfLAbvrDwEvKW9gwt-LeX5LN92W6A1JYttrZ9kVEhCPZ5xtOyGZu5NXGGKzC95q'
                                        target='_blank'
                                        className={s.signal}></a>
                                    <a
                                        href='https://instagram.com/turkishintinvbank?igshid=YmMyMTA2M2Y='
                                        target='_blank'
                                        className={s.insta}></a>
                                </div>
                                {/*<div className={s.lng_block}>*/}
                                {/*    <div className={localStorage.getItem('lang') === 'en' ? s.active_lang : s.lng}*/}
                                {/*         onClick={handleChangeLanguage}>*/}
                                {/*        EN*/}
                                {/*    </div>*/}
                                {/*    <div className={localStorage.getItem('lang') === 'ru' ? s.active_lang : s.lng}*/}
                                {/*         onClick={handleChangeLanguage}>*/}
                                {/*        RU*/}
                                {/*    </div>*/}
                                {/*    <div className={localStorage.getItem('lang') === 'tr' ? s.active_lang : s.lng}*/}
                                {/*         onClick={handleChangeLanguage}>*/}
                                {/*        TR*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className={s.mobile_footer}>
                <div className={s.social_links_block}>
                    <a href='https://www.facebook.com/turkish.international.bank' target='_blank' className={s.facebook}></a>
                    <a
                        href='https://www.linkedin.com/company/turkish-international-investment-bank'
                        target='_blank'
                        className={s.linkedin}></a>
                    <a href='https://wa.me/+447700306857' target='_blank' className={s.whatsapp}></a>
                    <a href='mailto:info@turkibu.com' target='_blank' className={s.mail}></a>
                    <a href='https://t.me/+447700306857' target='_blank' className={s.telegram}></a>
                    <a
                        href='https://signal.group/#CjQKIClfotfLAbvrDwEvKW9gwt-LeX5LN92W6A1JYttrZ9kVEhCPZ5xtOyGZu5NXGGKzC95q'
                        target='_blank'
                        className={s.signal}></a>
                    <a href='https://instagram.com/turkishintinvbank?igshid=YmMyMTA2M2Y=' className={s.insta}></a>
                </div>

                <div className={s.allRightsBlock}>
                    <span>
                        © {new Date().getFullYear()} {t("main.all_rights")}
                    </span>
                    <a className={s.line} href={getTermsAndConditionsLink()} target='_blank'>
                        <span>{t("main.terms")}</span>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(Footer);
