import React from 'react';

export const Helper = {
    checkAuth(auth) {
        const {isAuth = null} = auth;
        if (isAuth) {
            return isAuth;
        }
        return false;
    },
};

