import React from 'react'
import MainScreen from '../../Components/mainScreen/MainScreen'
import Guide from '../../Components/apiGuideContent/ApiGuideContent'
import { t } from 'i18next'

const ApiGuide = () => {
  return (
    <div>
      <MainScreen
        title={t('public_routes.api_guide')}
        pageName={t('main.banking')}
      />
      <Guide />
    </div>
  )
}

export default ApiGuide
