import {createSlice} from "@reduxjs/toolkit";

let initialState = {
  data: {
    accounts: [],
    client_first_name: "",
    client_id: "",
    client_last_name: "",
    company_name: "",
    email: "",
  },
  transactionsList: [],
  accountsInfo: {},
  totalUsersCount: 0,
  currentPage: 1,
};


export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData(state, action) {
      state.data = action.payload;
    },
    setTransactions(state, action) {
      state.transactionsList = action.payload;
    },
    clearState(state, action) {
      state.data = null;
      state.transactionsList = [];
      state.accountsInfo = {};
    }
  },
});

export default userSlice.reducer;
