import React from 'react'
import MainScreen from '../../Components/mainScreen/MainScreen'
import s from './SepaSwift.module.css'
import point from '../../assets/newImg/point.png'
import bottomBg from '../../assets/newImg/swiftSepa/backgroundContent.png'
import MyBtn from '../../Components/myBtn/MyBtn'
import { DEBIT_CARDS_ACCOUNT_OPENING } from '../../router/Constants'
import { useHistory } from 'react-router-dom'
import { t } from 'i18next'

const SepaSwift = () => {
  const redirect = useHistory()

  return (
    <div>
      <MainScreen
        title={t('public_routes.SEPA_SWIFT')}
        pageName={t('main.e_money')}
      />

      <div className={s.container}>
        <div className={s.descriptionTwoLine}>
          <div className={s.lineShort}></div>
          <div className={s.textBlockTwoLine}>
            <div>{t('SEPA_SWIFT.text1')}</div>
          </div>
          <div className={s.lineShort}></div>
        </div>

        <div className={s.descriptionBlock}>
          <div className={s.textBlock}>{t('SEPA_SWIFT.text2')}</div>
          <div className={s.line}></div>
          <div className={s.listBlock}>
            <div className={s.pointBlock}>
              <div className={s.point}>
                <img src={point} alt='' />
              </div>
              <div className={s.pointText}>{t('SEPA_SWIFT.line1')}</div>
            </div>
            <div className={s.pointBlock}>
              <div className={s.point}>
                <img src={point} alt='' />
              </div>
              <div className={s.pointText}>{t('SEPA_SWIFT.line2')}</div>
            </div>
            <div className={s.pointBlock}>
              <div className={s.point}>
                <img src={point} alt='' />
              </div>
              <div className={s.pointText}>{t('SEPA_SWIFT.line3')}</div>
            </div>
          </div>
        </div>
      </div>

      <div className={s.bottomWrapper}>
        <img className={s.bottomBg} src={bottomBg} alt='' />
        <div className={s.btnWrapper}>
          <MyBtn
            title={t('main.open_account')}
            onClick={() => {
              redirect.push(DEBIT_CARDS_ACCOUNT_OPENING)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default SepaSwift
