import React, { useState } from 'react'
import { Row, Col, Stack } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import Account from '../../Account'
import s from './Statement.module.css'
import StatementTable from './statementTable/StatementTable'
import { withTranslation } from 'react-i18next'
import { t } from 'i18next'

const Statement = () => {
  const state = useSelector(state => state)
  const { UserReducer: user } = state

  const [isShowStatementTable, SetIsShowStatementTable] = useState(false)
  const [accountNumber, SetAccountNumber] = useState(null)

  return (
    <Account activeTab='statement' title={t('personal.statement_page.title')}>
      {isShowStatementTable ? (
        <StatementTable
          accountNumber={accountNumber}
          statementTableData={user.transactionsList}
        />
      ) : (
        <div className=''>
          <Row>
            {user?.data?.accounts && user?.data?.accounts.length > 0 ? (
              user?.data?.accounts.map((item, index) => {
                return (
                  <Col sm={6}>
                    <div
                      className={`${s.card} mb-3 px-3 py-4`}
                      key={item.account_special_number}
                      onClick={() => {
                        SetAccountNumber(item.account_special_number)
                        SetIsShowStatementTable(true)
                      }}>
                      <Stack direction='horizontal' className={s.values_title}>
                        <span className={`"me-auto" ${s.acc_num_title}`}>
                          {t('personal.statement_page.acc_num')}
                        </span>
                        <span className={`"me-auto" ${s.currency_title}`}>
                          {t('personal.statement_page.currency')}
                        </span>
                      </Stack>
                      <Stack direction='horizontal'>
                        <b className='me-auto'>
                          {item?.account_special_number}
                        </b>
                        <b className={s.currency_value}>
                          {item?.currency_abbreviation}
                        </b>
                      </Stack>
                    </div>
                  </Col>
                )
              })
            ) : (
              <small className='tex-danger'>Hesap bulunamadı</small>
            )}
          </Row>
        </div>
      )}
    </Account>
  )
}

export default withTranslation()(Statement)
