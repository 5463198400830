import React from 'react'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      website_title:
        'Offshore Banking - Offshore Bank Account | Turkish International Investment Bank',
      main: {
        turkey: 'TURKISH',
        international: 'INTERNATIONAL',
        investment: 'INVESTMENT',
        bank: 'BANK',
        login: 'Login',
        logout: 'Logout',
        sign_up: 'open account',
        contact_us: 'Contact Us',
        investor_relations: 'Investor relations',
        banking: 'Banking',
        savings_account: 'Savings Account',
        debit_cards: 'Debit Cards',
        e_money: 'E-Money',
        about_us: 'About Us',
        api_guide: 'API Guide',
        desc_title: 'Full set of general and private banking services',
        desc_subtitle:
          'Experienced, professional management and stellar Board of\n' +
          '                            Directors. Strict but reasonable compliance policies.',
        open_account: 'open account',
        banking_service: 'Banking Service',
        all_rights:
          'Turkish International Investment Bank Limited. All rights reserved.',
        banking_service_content:
          'Full set of general and private banking services. Experienced,\n' +
          '                                professional management and stellar Board of Directors. Strict\n' +
          '                                but reasonable compliance policies. Multi-currency accounts for\n' +
          '                                global payments and high-yield deposits. Universally accepted\n' +
          '                                debit cards. Fast global money transfers. Superb account\n' +
          '                                security and data protection. A full suite of e-banking tools\n' +
          '                                online and in-app. Seamless link to crypto asset. Friendly,\n' +
          "                                competent support staff. That's what we do and who we are!",
        universal_personal_accounts: 'Universal personal accounts',
        universal_personal_accounts_desc:
          'TURKISH INTERNATIONAL INVESTMENT BANK offers personal accounts, which can be applied for\n' +
          '                                and opened online. The package of features of your personal account will be crafted to\n' +
          '                                perfectly fit your needs. Our general banking accounts offer everything you require to\n' +
          '                                simplify and streamline the daily financial life of your whole family. TURKISH\n' +
          '                                INTERNATIONAL INVESTMENT BANK clients enjoy the level of personalized management of their\n' +
          '                                banking and investment portfolios unprecedented among our industry peers across the\n' +
          '                                globe.',
        fueling_your_e_commerce: 'Fueling your e-commerce',
        fueling_your_e_commerce_desc:
          'TURKISH INTERNATIONAL INVESTMENT BANK provides everything you need to launch and manage\n' +
          '                                your international e-commerce. Our ability to manage multiple currencies, support\n' +
          '                                locations in key parts of the world, our network of reliable correspondent banks and the\n' +
          '                                expert IT team - is exactly what you need to launch and operate the most challenging\n' +
          "                                business e-commerce missions in a stable and reasonably-priced environment you've been\n" +
          '                                looking for.',
        acquiring_services: 'Acquiring services',
        acquiring_services_desc_p1:
          'The Turkish International Investment Bank will perform technical support of one of the most important services of the site - acquiring. Acceptance of payments on the site should work like clockwork, because it depends on how quickly and accurately payments will be processed. You will be able to offer your new clients several methods of withdrawal.',
        acquiring_services_desc_p2: 'Cooperating with us means:',
        acquiring_services_desc_p3:
          '-Guaranteed security of assets and personal data of clients.',
        acquiring_services_desc_p4: '-Qualified technical support 24/7.',
        acquiring_services_desc_p5:
          '-Possibility to accept and process payments in cryptocurrency.',
        acquiring_services_desc_p6: '-Intuitive interface.',
        saving: 'Savings',
        account: 'Account',
        deposit_with: 'Deposit with Turkish International',
        investment_bank: 'Investment Bank',
        saving_desc:
          'TURKISH INTERNATIONAL INVESTMENT BANK pays up to 7% on Certificates of Deposits in Savings\n' +
          '                            Accounts in USD and EUR. No strings attached, just straight\n' +
          '                            and simple quarterly payments of interest. You must be an existing\n' +
          '                            TURKISH INTERNATIONAL INVESTMENT BANK account-holder to qualify.',
        debit_cards_block_title: 'Debit Cards',
        debit_cards_block_subtitle:
          'Visa® and UnionPay debit cards for you and your partners',
        debit_cards_block_desc:
          'TIIB virtual or bank card will provide you with access to your money at any ' +
          'time and everywhere. Our 3D security system for your online payments will protect your finances ' +
          'from fraudsters. You can use an ATM or pay for your purchase in any store worldwide. Your finances ' +
          'have no borders with Turkish International Investment Bank payment cards.',
        debit_cards_block_desc2:
          'Open an account today at TIIB and feel the freedom of your money!',
        e_money_title_1: 'For business, you and yours',
        e_money_desc_1:
          "TURKISH INTERNATIONAL INVESTMENT BANK is a global financial institution with a personal touch. If you're running a huge business or just a small errand - we may have a financial solution for you!\n" +
          'We are both international and multi-currency and as local as the ATM around the corner. Combining brick and mortar stability with the high-tech security of our cloud-based\n' +
          'e-wallets, we are everything you want your global bank to be!',
        e_money_title_2: 'SEPA, SWIFT',
        e_money_desc_2:
          'We offer accounts in various currencies with access to the\n' +
          '                                European SEPA and international SWIFT systems. Private bankers\n' +
          "                                are available to the high net worth clients while everyone's\n" +
          '                                daily needs are addressed by our versatile personal e-banking\n' +
          '                                solutions, including virtual and prepaid debit cards, fast\n' +
          '                                peer-to-peer transfers and multiple other cool features managed\n' +
          '                                online.',
        privacy:
          'Turkish International Investment Bank Limited. All rights reserved.',
        terms: 'Terms and Conditions',
        send: 'send',
        valid_email: 'Enter a valid email',
        all_fields: 'All fields must be filled',
        slider: {
          TIIB: 'TURKISH INTERNATIONAL INVESTMENT BANK',
          experience:
            'Experience The Unique Set of General and Private Banking Services',
        },
      },
      home: {
        europe_asia_africa:
          'We specialize in delivering complete payment solutions to our merchants worldwide through the largest correspondent banks in Europe, Asia and Africa. We are Not CRS compliant meaning we do              not share any information with any government organization.',
        FATCA:
          'We are Not FATCA compliant, so we do not share information with the American authorities for our American clients.',
        TIIB_provides_corporate:
          'Turkish International Investment Bank provides a full set of corporate and private banking services:',
        gaming_gambling_forex:
          'We offer payment solutions, especially for high-risk businesses such as gaming, gambling, forex, crypto exchange. We are flexible with all nationalities.',
        We_provide_various_types_of_accounts:
          'We provide various types of accounts, for instance:',
        Shared_bank_account_with_a_common_IBAN:
          "Shared bank account with a common IBAN number. All transactions will be done using our bank's name so it is beneficial for people who they do not want to show their identity.",
        Dedicated_account_with_SWIFT:
          "Dedicated account with SWIFT/ SEPA code. In this case, all transactions will be done by the clients' name.",
        Merchant_account_with_TIIB_Card:
          'Merchant account with TIIB Card will be the ideal tool to manage your funds around the globe. You will be able to access funds with a virtual or real debit card linked directly to your TIIB Account, shop in-store or online everywhere Visa® - branded cards are accepted, make safe online payments with 3D Secure Push, withdraw cash from ATMs worldwide and enjoy other features associated with the Visa® - branded debit cards.',
      },
      public_routes: {
        main_page: 'Home',
        general_information: 'General information',
        organizational_structure: 'Organizational Structure',
        compliance_and_documents: 'Compliance and Documents',
        universal_personal_accounts: 'Universal Uersonal Accounts',
        acquiring_services: 'Acquiring Services',
        api_guide: 'API Guide',
        fueling_your_e_commerce: 'Fueling your E-Commerce',
        banking_service: 'Banking Service',
        deposit_with_tiib: 'Deposit with TIIB',
        calculate_amount_of_interest: 'Calculate amount of interest',
        visa_and_union_pay_debit_cards: 'Visa and Union Pay Debit Cards',
        account_opening: 'Account Opening',
        for_business_and_yours: 'For Business and Yours',
        SEPA_SWIFT: 'SEPA, SWIFT',
        form_submissions: 'Form Submissions',
        contact_information: 'Contact Information',
      },
      banking_services: {
        swift: 'INTERNATIONAL SWIFT AND SEPA WIRE TRANSFERS',
        safety: 'SAVING ACCOUNTS',
        crypto: 'CRYPTO PAYMENTS',
        my_card: 'DEBIT CARDS',
        processingCards: 'CARD PROCESSING',
        chart: 'INVESTMENT PROGRAMS',
        money: 'MULTICURRENCY BANK ACCOUNT',
        infinite: 'UNLIMITED TRANSFER',
        anonymous: 'ANONYMOUS PAYMENTS',
        distance: 'REMOTE ACCOUNT OPENING',
        support: 'DEDICATED MANAGER',
      },
      general_info: {
        banking_built_differently: 'BANKING BUILT DIFFERENTLY',
        text1:
          'Better Bank Better Life: our focus is on making your life easier in all the little ways. We personalize the banking experience for each of our customers to help them reach new heights and new destinations.',
        relationships: 'RELATIONSHIPS',
        text2:
          'We know you because we want to know you, and we value customer interactions and service. At other banks, you’re nothing more than an account balance.',
        flexibility_convenience: 'FLEXIBILITY & CONVENIENCE',
        text3:
          'We offer easy access to decision makers and you work with the same team throughout the process.',
        commitment: 'COMMITMENT',
        text4:
          'We stand by our customers and our products throughout the banking relationship.',
        longevity: 'LONGEVITY',
        text5:
          "We have been owned and operated by the same people since and we have very little turnover. At other banks, constant mergers and acquisitions mean you always have new banks and new terms, and new bankers, who don't know you or your story.",
        make_life_easier: 'We make your life easier',
        text6:
          'We make your life easier for all of these reasons and the little reasons words can’t describe. At TIIB, we believe there is no better way to focus on service than by getting to know our customers. We aim to be a different kind of bank by making your life better whether at home or at work. THAT IS OUR MISSION.',
        text7:
          'We combine all the advantages of a smaller bank with the technology and convenience of a large institution. It truly is the best of both worlds.',
      },
      organizational_structure: {
        head_board: 'Head of the Board',
        board_member: 'Board member',
      },
      compliance_and_documents: {
        pdf1: 'TIIB-data-protection-policy.pdf',
        pdf2: 'TIIB-policy.pdf',
        open: 'open',
        download: 'download',
      },
      universal_personal_accounts: {
        text1:
          'TURKISH INTERNATIONAL INVESTMENT BANK offers personal accounts, which can be applied for and opened online. The package of features of your personal account will be crafted to perfectly fit your needs.',
        text2:
          'Our general banking accounts offer everything you require to simplify and streamline the daily financial life of your whole family.',
        text3: 'We save Your time!',
        text4:
          'TURKISH INTERNATIONAL INVESTMENT BANK clients enjoy the level of personalized management of their banking and investment portfolios unprecedentedly among our industry peers across the globe.',
      },
      acquiring_services: {
        text1:
          'TURKISH INTERNATIONAL INVESTMENT BANK will perform technical support of one of the most important services of the site - acquiring.',
        text2:
          'Acceptance of payments on the site should work like clockwork because it depends on how quickly and accurately payments will be processed. You will be able to offer your new clients several methods of withdrawal.',
        accounts_types: 'We are providing many accounts types for instance:',
        type1: 'Guaranteed security of assets and personal data of clients.',
        type2: 'Qualified technical support 24/7.',
        type3: 'Possibility to accept and process payments in cryptocurrency.',
        type4: 'Intuitive interface.',
      },
      fueling_your_e_commerce: {
        title1: 'TURKISH INTERNATIONAL INVESTMENT BANK',
        subtitle1:
          'provides everything you need to launch and manage your international e-commerce.',
        card1: 'OUR ABILITY TO MANAGE MULTIPLE CURRENCIES',
        card2: 'Our support locations in key parts of the world',
        card3: 'Our network of reliable correspondent banks',
        card4: 'OUR EXPERT IT TEAM',
        title2:
          'It’s exactly what you need to launch and operate the most challenging business e-commerce missions in a stable and reasonably.',
        subtitle2: "Priced environment you've been looking for.",
      },
      banking_service: {
        title: "That's what we do and who we are!",
        line1: 'Full set of general and private banking services.',
        line2:
          'Experienced, professional management and stellar Board of Directors.',
        line3: 'Strict but reasonable compliance policies.',
        line4:
          'Multi-currency accounts for global payments and high-yield deposits.',
        line5: 'Universally accepted debit cards.',
        line6: 'Timely global money transfers (Western Union).',
        line7: 'Superb account security and data protection.',
        line8: 'A full suite of e-banking tools online and in-app.',
        line9: 'Seamless link to crypto asset.',
        line10: 'Friendly, competent support staff.',
      },
      deposit_with_tiib: {
        title:
          'TURKISH INTERNATIONAL INVESTMENT BANK pays up to 7% on Certificates of Deposits in Savings Accounts in USD and EUR. No strings attached, just straight and simple quarterly payments of interest.',
        subtitle:
          'You must be an existing TURKISH INTERNATIONAL INVESTMENT BANK account holder to qualify.',
      },
      calc: {
        title: 'Calculate amount of interest',
        deposit_amount: 'deposit amount',
        period: 'period',
        month: 'months',
        currency: 'currency',
        open_account: 'Account opening',
        interest: 'Interest',
        in: 'in',
        month_2: 'months',
        calc_sentence_1: 'you may earn',
        calc_sentence_2: 'of interest on your deposit',
      },
      visa_and_union_pay_debit_cards: {
        title: 'Visa® and Union Pay debit cards for you and your partners',
        subtitle:
          'TIIB virtual or bank card will provide you with access to your money at any time and everywhere. Our 3D security system for your online payments will protect your finances from fraudsters. You can use an ATM or pay for your purchase in any store worldwide. Your finances have no borders with Turkish International Investment Bank payment card ',
      },
      account_opening: {
        title: 'Online Account Opening request',
        subtitle: 'Soon our specialists will contact you to create',
        text: 'Open an account today at TIIB and feel the freedom of your money!',
      },
      for_business_and_yours: {
        text: ' TURKISH INTERNATIONAL INVESTMENT BANK is a global financial institution with a personal touch',
        line1:
          "If you're running a large business or just a small errard - we may have a financial solution for you!",
        line2:
          'We are both international and multi-currency and as local as the ATM around the corner.',
        line3:
          'Combining brick and mortar stability with the high-tech security of our cloud-based e-wallets, we everything you want your global bank to be!',
      },
      SEPA_SWIFT: {
        text1:
          'We offer accounts in various currencies with access to the European SEPA and international SWIFT systems.',
        text2: 'We are providing many accounts types for instance:',
        line1: 'Virtual and prepaid debit cards.',
        line2: 'Fast peer-to-peer transfers.',
        line3: 'Multiple other cool features managed online.',
      },
      form_submissions: {
        people_trust_us: 'People trust us!',
        name: 'Name',
        phone: 'Phone',
        message: 'Message',
        email: 'E-mail',
        send: 'send',
      },
      contact_information: {
        title1:
          'We offer accounts in various currencies with access to the European SEPA and international SWIFT systems.',
        reach_us: 'You can reach us:',
        title2:
          'Bizi sosyal ağlarda ve anlık mesajlaşma uygulamalarında bulun:',
      },
      contact: {
        contact: 'Contact ',
        us: 'Us',
        reach_us: 'You can reach us',
        welcome_to: 'Welcome to Turkish International Investment Bank',
        name: 'Name',
        phone: 'Phone',
        message: 'Message',
        cyprus_title: 'Representative office in Cyprus:',
        cyprus_address_1: '57 Spyrou Kyprianou Avenue,',
        cyprus_address_2: 'Bybloserve Business Center,',
        cyprus_address_3: 'Larnaca 6051, Cyprus',
      },
      popup: {
        title: 'Send a request',
        subtitle: 'Soon our specialists will contact you to create',
        name: 'Name',
        phone: 'Phone',
        email: 'E-mail',
        send: 'send',
        success: 'Success!',
        ok: 'OK',
        title_opening: 'Online Account Opening request',
        download: 'Download the Account Opening Form',
        personal: 'Account Opening Form Personal',
        corporate: 'Account Opening Form Corporate',
        upload: 'Upload the Completed and Signed Form',
        add: 'ADD',
        contact_info: 'Your Contact Information',
        oops: 'Oops',
        wrong: 'Something went wrong!',
      },
      investor_relations: {
        investor: 'Investor',
        relations: 'Relations',
        name: 'Turkish International Investment Bank',
        trust: 'People trust us',
        description:
          'This section provides shareholders, analysts, the media and other interested parties with\n' +
          "                            relevant information, enabling a transparent assessment of the company's value.",
        '': '',
      },
      login: {
        login: 'Login',
        e_mail: 'E-mail/Username',
        password: 'Password',
        dont_have_account: "If you don't have an account",
        sign_up: 'sign up',
        all_rights:
          'Turkish International Investment Bank Limited. All rights reserved.',
        back: 'back',
        terms_and_conditions: 'Terms and Conditions',
        confirm: 'Confirm',
      },
      personal: {
        exit: 'EXIT',
        details: 'Account Details',
        statement: 'Statement',
        swift: 'SWIFT/SEPA Transfer',
        exchange: 'Exchange Transfer',
        internal: 'Internal Transfer',
        transfer: 'TRANSFER',
        account_details: {
          title: 'Client Details',
          id: 'Client ID:',
          company_name: 'Company name:',
          client_name: 'Client name:',
          contact_details: 'Contact details:',
          acc_num: 'Account number',
          acc_type: 'Account type',
          currency: 'Currency',
          acc_balance: 'Account Balance',
          sec_deposit: 'Security Deposit',
        },
        statement_page: {
          title: 'Your Account',
          acc_num: 'Account number',
          currency: 'Currency',
          filter_title: 'Filter By The Account',
          date: 'Date',
          transfer_type: 'Transfer type',
          payment: 'Payment',
          transaction_number: 'Transaction number',
          debit: 'Debit',
          credit: 'Credit',
          status: 'Status',
          balance: 'Balance',
        },
        swift_page: {
          transactions_details: 'Transaction Details',
          swift_code: 'SWIFT Code',
          bank_name: 'Bank Name',
          country: 'Country',
          city: 'City',
          benef_address: 'Beneficiary Address',
          benef_name: 'Beneficiary Name',
          acc_num: 'Account number',
          bank_address: 'Bank Address',
          transfer_details: 'Transfer Details',
          amount: 'Amount',
          other: 'Other Details',
          purpose: 'Purpose of transfer',
          transfer: 'TRANSFER',
          i_agree_en: 'I agree with Turkish International Investment Bank',
          terms_en: 'Terms and Conditions',
          i_agree_tu_p1: '',
          terms_tu: '',
          i_agree_tu_p2: '',
          i_agree_ru_p2: '',
          i_agree_ru_p1: '',
          terms_ru: '',
        },
        exchange_and_internal_page: {
          exchange_title:
            'Convert Currency and/or Initiate an Exchange Transfer',
          from_acc: 'From Account',
          to_acc: 'To Account',
          select: 'Select account',
          amount: 'Amount',
          payment_ref: 'Payment Reference',
          internal_title: 'Initiate an Internal Transfer',
        },
      },
      api_guide: {
        api: 'API',
        guide: 'Guide',
        start: 'Start using',
        it_now: 'it now!',
        difficulties: 'If you have any difficulties, please contact us',
        json: 'Parameters are passed in JSON format',
        headers_block: 'Headers block is required for all types of requests',
        example: 'Example:',
        available_endpoints: 'Available endpoints',
        log_in: 'Log In',
        response: 'Response',
        tool_signature_request: 'Tool Signature Request',
        transaction_status_request: 'Transaction status request',
        refund: 'Refund',
        transaction_info: 'Transaction info',
        transactions_list: 'Transactions list:',
      },
    },
  },
  ru: {
    translation: {
      website_title:
        'Офшорный банкинг - Офшорный банковский счет | Турецкий Международный Инвестиционный Банк',
      main: {
        turkey: 'ТУРЕЦКИЙ',
        international: 'МЕЖДУНАРОДНЫЙ',
        investment: 'ИНВЕСТИЦИОННЫЙ',
        bank: 'БАНК',
        login: 'логин',
        logout: 'logout',
        sign_up: 'открыть счёт',
        contact_us: 'свяжитесь с нами',
        investor_relations: 'Investor relations',
        banking: 'Финансовые услуги',
        savings_account: 'Депозитный счёт',
        debit_cards: 'Платёжные карты',
        e_money: 'Международные платежи',
        about_us: 'О нас',
        api_guide: 'API guide',
        desc_title:
          'Полный спектр банковских услуг для физических и юридических лиц',
        desc_subtitle:
          'Опытность и профессионализм. Оптимальная строгость банковского надзора',
        open_account: 'Открыть счёт',
        banking_service: 'Банковские услуги',
        banking_service_content:
          'Полный спектр банковских услуг для физических и юридических лиц. Опытность и профессионализм нашей команды. Оптимальная строгость банковского надзора. Мультивалютные счета для международных платежей и депозитов  с  высокой  доходностью. Международные платёжные карты.  Быстрые международные денежные  переводы.  Высокий уровень безопасности и защиты данных. Полный набор инструментов для дистанционного банковского обслуживания. Прямая связь с криптоактивами. Круглосуточная служба поддержки. Наша работа - это Ваш прогресс!',
        universal_personal_accounts: 'Расчётный счёт',
        universal_personal_accounts_desc:
          'Турецкий Международный Инвестиционный Банк предоставляет возможность открыть расчетный счёт онлайн.  Мы приспособим личный кабинет каждого пользователя, учитывая его потребности и пожелания. Наши универсальные расчётные счета обладают всеми необходимыми свойствами, чтобы упростить и оптимизировать работу с Вашими финансами. Клиенты Турецкого Международного Инвестиционного Банка имеют доступ к самым современным технологиям банковского обслуживания и управления инвестиционным портфелем. Мы стремимся быть лидерами в банковской сфере.',
        fueling_your_e_commerce: 'Поддержка онлайн бизнеса',
        fueling_your_e_commerce_desc:
          'Турецкий Международный Инвестиционный Банк предоставляет всестороннюю поддержку при запуске и управлении онлайн бизнеса наших клиентов. Мы предоставляем мультивалютные счета, широкую сеть банков корреспондентов и надёжные международные деловые контакты. Наша команда ИТ-экспертов постоянно модернизирует системы, которые позволяют управлять самыми сложными бизнес-моделями онлайн торговли, выводя их на новый международный уровень стабильности и безопасности.',
        acquiring_services: 'Эквайринг',
        acquiring_services_desc_p1:
          'Турецкий Международный Инвестиционный Банк технологически поддерживает одну из главных своих услуг  - эквайринг.\n' +
          'Обработка платёжных поручений должна быть точна и своевременна как часы, поскольку от этого зависит точность и скорость проведения банковской операции. Благодаря нам Вы сможете предложить своим партнёрам несколько форм денежных переводов.',
        acquiring_services_desc_p2: 'Наши клиенты могут рассчитывать на:',
        acquiring_services_desc_p3:
          '- Гарантированную безопасность активов и личных данных;',
        acquiring_services_desc_p4:
          '- Квалифицированную техническую поддержку 24/7;',
        acquiring_services_desc_p5:
          '- Возможность принимать и обрабатывать платежи в криптовалютах;',
        acquiring_services_desc_p6: '- Интуитивный пользовательский интерфейс.',
        saving: 'Депозитный',
        account: 'счёт',
        deposit_with: 'Депозитные сертификаты ТМИБ',
        investment_bank: '',
        all_rights:
          'Турецкий Международный Инвестиционный Банк Лимитед. Все права защищены.',
        saving_desc:
          'Турецкий Международный Инвестиционный Банк (ТМИБ) выплачивает до 7% по депозитным\n' +
          'сертификатам в долларах США и евро. Никаких\n' +
          'ограничений на вклады, ясная и чёткая система ежеквартальных выплат дивидендов. Единственное, что\n' +
          'Вам необходимо, это быть клиентом ТМИБ.',
        debit_cards_block_title: 'Платёжные карты',
        debit_cards_block_subtitle:
          'Платёжные карты Visa® и UnionPay для Вас и Ваших партнёров',
        debit_cards_block_desc:
          'Виртуальная или пластиковая платёжная карта ТМИБ предоставит Вам доступ к ' +
          'Вашим финансам в любое время и в любом месте. Наша система 3D защиты Ваших онлайн платежей убережёт ' +
          'Ваши финансы от мошенников. В любой точке мира Вы можете воспользоваться банкоматом или оплатить ' +
          'Вашу покупку в магазине. Ваши финансы не имеют границ с платёжными карточками Турецкого Международного ' +
          'Инвестиционного Банка.',
        debit_cards_block_desc2:
          ' Откройте счёт сегодня в ТМИБ и почувствуйте свободу Ваших денег!',
        e_money_title_1: 'Решения для Вашего бизнеса',
        e_money_desc_1:
          'Турецкий Международный Инвестиционный Банк - финансовая организация международного ' +
          'масштаба с индивидуальным подходом к каждому клиенту. Неважно, если Вы большая корпорация или ' +
          'начинающий малый бизнес - мы всегда найдём оптимальное решение для ваших финансов! ТМИБ - это и ' +
          'международные мультивалютные переводы, и представительства во многих странах, и банкомат, который ' +
          'Вы всегда найдёте рядом с Вами. ТМИБ - это союз стабильности и высокотехнологичной безопасности Ваших счетов на международном финансовом рынке!',
        e_money_title_2: 'SEPA, SWIFT',
        e_money_desc_2:
          'Мы предлагаем широкий выбор разных типов расчётных счетов в различных валютах для платежей в европейской системе SEPA и международной системе SWIFT.\n' +
          'Благодаря нашему интернет-банкингу Вам не нужен будет личный банкир. В режиме онлайн Вы сможете ' +
          'решить все вопросы, связанные с Вашими финансами: осуществить денежный перевод, заказать платёжную ' +
          'карту или выбрать услуги, которые полностью удовлетворят потребности Вашего бизнеса. И всё это 24/7!',
        privacy:
          '© 2023 Турецкий Международный Инвестиционный Банк Лимитед. Все права защищены.',
        terms: 'Условия и Положения',
        send: 'отправить',
        valid_email: 'Введите корректный Email',
        all_fields: 'Заполните все поля',
        slider: {
          TIIB: 'ТУРЕЦКИЙ МЕЖДУНАРОДНЫЙ ИНВЕСТИЦИОННЫЙ БАНК',
          experience:
            'Ощутите уникальный набор общих и частных банковских услуг',
        },
      },
      home: {
        europe_asia_africa:
          'Мы специализируемся на предоставлении полных платежных решений нашим клиентам по всему миру через крупнейшие банки-корреспонденты в Европе, Азии и Африке. Мы не соответствуем требованиям CRS, что означает, что мы не делимся никакой информацией с государственными организациями.',
        FATCA:
          'Мы не соответствуем требованиям FATCA, поэтому не делимся информацией с американскими властями о наших американских клиентах.',
        TIIB_provides_corporate:
          'Турецкий Международный Инвестиционный Банк предоставляет полный спектр корпоративных и частных банковских услуг:',
        gaming_gambling_forex:
          'Мы предлагаем платежные решения, особенно для высокорисковых бизнесов, таких как азартные игры, азартные игры, форекс, криптообмен. Мы гибки в отношении всех национальностей.',
        We_provide_various_types_of_accounts:
          'Мы предлагаем различные типы счетов, например:',
        Shared_bank_account_with_a_common_IBAN:
          'Общий банковский счет с общим номером IBAN. Все транзакции будут выполнены от имени нашего банка, что выгодно для людей, не желающих раскрывать свою личность.',
        Dedicated_account_with_SWIFT:
          'Именной счет с кодом SWIFT/SEPA. В этом случае все транзакции будут проводиться на имя клиента.',
        Merchant_account_with_TIIB_Card:
          'Торговый счет с картой TIIB будет идеальным инструментом для управления вашими средствами по всему миру. Вы сможете получить доступ к средствам с помощью виртуальной или реальной дебетовой карты, связанной напрямую с вашим счетом TIIB, совершать покупки в магазинах или онлайн везде, где принимаются карты с брендом Visa®, совершать безопасные онлайн-платежи с использованием 3D Secure Push, снимать наличные в банкоматах по всему миру и наслаждаться другими функциями, связанными с дебетовыми картами с брендом Visa®.',
      },
      public_routes: {
        main_page: 'Главная',
        general_information: 'Общая информация',
        organizational_structure: 'Организационная структура',
        compliance_and_documents: 'Соответствие и документы',
        universal_personal_accounts: 'Универсальные личные счета',
        acquiring_services: 'Эквайринговые услуги',
        api_guide: 'Руководство по API',
        fueling_your_e_commerce: 'Поддержка вашей электронной коммерции',
        banking_service: 'Банковское обслуживание',
        deposit_with_tiib: 'Депозит в TIIB',
        calculate_amount_of_interest: 'Рассчитать сумму процентов',
        visa_and_union_pay_debit_cards: 'Дебетовые карты Visa и Union Pay',
        account_opening: 'Открытие счета',
        for_business_and_yours: 'Для бизнеса и для вас',
        SEPA_SWIFT: 'SEPA, SWIFT',
        form_submissions: 'Отправка форм',
        contact_information: 'Контактная информация',
      },
      banking_services: {
        swift: 'МЕЖДУНАРОДНЫЕ ПЕРЕВОДЫ SWIFT И SEPA',
        safety: 'СБЕРЕГАТЕЛЬНЫЕ СЧЕТА',
        crypto: 'КРИПТО ПЛАТЕЖИ',
        my_card: 'ДЕБЕТОВЫЕ КАРТЫ',
        processingCards: 'ОБРАБОТКА КАРТ',
        chart: 'ИНВЕСТИЦИОННЫЕ ПРОГРАММЫ',
        money: 'МНОГОВАЛЮТНЫЙ БАНКОВСКИЙ СЧЕТ',
        infinite: 'НЕОГРАНИЧЕННЫЕ ПЕРЕВОДЫ',
        anonymous: 'АНОНИМНЫЕ ПЛАТЕЖИ',
        distance: 'ДИСТАНЦИОННОЕ ОТКРЫТИЕ СЧЕТА',
        support: 'ПЕРСОНАЛЬНЫЙ МЕНЕДЖЕР',
      },
      general_info: {
        banking_built_differently: 'БАНКИНГ, ПОСТРОЕННЫЙ ИНАЧЕ',
        text1:
          'Лучший банк — лучшая жизнь: наш фокус на том, чтобы сделать вашу жизнь легче во всех мелочах. Мы персонализируем банковский опыт для каждого нашего клиента, чтобы помочь им достичь новых высот и новых горизонтов.',
        relationships: 'ОТНОШЕНИЯ',
        text2:
          'Мы знаем вас, потому что хотим знать вас, и ценим взаимодействие с клиентами и сервис. В других банках вы — всего лишь баланс на счете.',
        flexibility_convenience: 'ГИБКОСТЬ И УДОБСТВО',
        text3:
          'Мы предлагаем легкий доступ к лицам, принимающим решения, и вы работаете с одной и той же командой на протяжении всего процесса.',
        commitment: 'ПРИВЕРЖЕННОСТЬ',
        text4:
          'Мы поддерживаем наших клиентов и наши продукты на протяжении всего банковского сотрудничества.',
        longevity: 'ДОЛГОВЕЧНОСТЬ',
        text5:
          'Мы принадлежим и управляемся одними и теми же людьми уже давно, и у нас очень низкая текучесть кадров. В других банках постоянные слияния и поглощения означают, что у вас всегда новые банки, новые условия и новые банкиры, которые не знают вас и вашу историю.',
        make_life_easier: 'Мы делаем вашу жизнь легче',
        text6:
          'Мы делаем вашу жизнь легче по всем этим причинам и из-за мелочей, которые трудно описать словами. В TIIB мы верим, что лучший способ сосредоточиться на обслуживании — это узнать наших клиентов. Мы стремимся быть другим типом банка, улучшая вашу жизнь, будь то дома или на работе. ЭТО НАША МИССИЯ.',
        text7:
          'Мы объединяем все преимущества небольшого банка с технологиями и удобством крупного учреждения. Это действительно лучшее из обоих миров.',
      },
      organizational_structure: {
        head_board: 'Глава Совета',
        board_member: 'Член Совета',
      },
      compliance_and_documents: {
        pdf1: 'TIIB-политика-защиты-данных.pdf',
        pdf2: 'TIIB-политика.pdf',
        open: 'открыть',
        download: 'скачать',
      },
      universal_personal_accounts: {
        text1:
          'ТУРЕЦКИЙ МЕЖДУНАРОДНЫЙ ИНВЕСТИЦИОННЫЙ БАНК предлагает персональные счета, которые можно оформить и открыть онлайн. Пакет функций вашего личного счета будет разработан таким образом, чтобы идеально соответствовать вашим потребностям.',
        text2:
          'Наши общие банковские счета предлагают все необходимое для упрощения и оптимизации повседневной финансовой жизни всей вашей семьи.',
        text3: 'Мы экономим ваше время!',
        text4:
          'Клиенты ТУРЕЦКОГО МЕЖДУНАРОДНОГО ИНВЕСТИЦИОННОГО БАНКА наслаждаются уровнем персонализированного управления своими банковскими и инвестиционными портфелями, который беспрецедентен среди наших коллег по отрасли по всему миру.',
      },
      acquiring_services: {
        text1:
          'ТУРЕЦКИЙ МЕЖДУНАРОДНЫЙ ИНВЕСТИЦИОННЫЙ БАНК будет осуществлять техническую поддержку одного из самых важных сервисов сайта - эквайринга.',
        text2:
          'Прием платежей на сайте должен работать как часы, потому что от этого зависит, насколько быстро и точно будут обрабатываться платежи. Вы сможете предложить своим новым клиентам несколько способов вывода средств.',
        accounts_types: 'Мы предлагаем множество типов счетов, например:',
        type1:
          'Гарантированная безопасность активов и персональных данных клиентов.',
        type2: 'Квалифицированная техническая поддержка 24/7.',
        type3: 'Возможность приема и обработки платежей в криптовалюте.',
        type4: 'Интуитивно понятный интерфейс.',
      },
      fueling_your_e_commerce: {
        title1: 'ТУРЕЦКИЙ МЕЖДУНАРОДНЫЙ ИНВЕСТИЦИОННЫЙ БАНК',
        subtitle1:
          'предоставляет все необходимое для запуска и управления вашим международным e-commerce.',
        card1: 'НАШИ ВОЗМОЖНОСТИ УПРАВЛЕНИЯ МНОГИМИ ВАЛЮТАМИ',
        card2: 'Наши центры поддержки в ключевых частях мира',
        card3: 'Наша сеть надежных банков-корреспондентов',
        card4: 'НАША ЭКСПЕРТНАЯ ИТ-КОМАНДА',
        title2:
          'Это именно то, что вам нужно для запуска и работы самых сложных бизнес-миссий в сфере электронной коммерции в стабильной и разумной среде.',
        subtitle2: 'Ценовая среда, которую вы искали.',
      },
      banking_service: {
        title: 'Это то, что мы делаем, и кем мы являемся!',
        line1: 'Полный спектр общих и частных банковских услуг.',
        line2:
          'Опытное, профессиональное руководство и выдающийся Совет директоров.',
        line3: 'Строгие, но разумные правила соблюдения.',
        line4:
          'Многовалютные счета для глобальных платежей и высокодоходных депозитов.',
        line5: 'Дебетовые карты, принимаемые во всем мире.',
        line6: 'Своевременные глобальные денежные переводы (Western Union).',
        line7: 'Отличная защита счета и данных.',
        line8:
          'Полный набор инструментов интернет-банкинга онлайн и в приложении.',
        line9: 'Бесшовная связь с криптоактивами.',
        line10: 'Дружелюбный и компетентный персонал поддержки.',
      },
      deposit_with_tiib: {
        title:
          'TURKISH INTERNATIONAL INVESTMENT BANK выплачивает до 7% по депозитным сертификатам на сберегательных счетах в долларах США и евро. Без скрытых условий, только прямые и простые ежеквартальные выплаты процентов.',
        subtitle:
          'Вы должны быть существующим держателем счета TURKISH INTERNATIONAL INVESTMENT BANK, чтобы соответствовать требованиям.',
      },
      calc: {
        title: 'Расчёт дивидендов: ',
        deposit_amount: 'сумма вклада',
        period: 'период',
        month: 'мес.',
        currency: 'валюта',
        open_account: 'Открыть счёт',
        interest: 'Проценты',
        in: 'за',
        month_2: 'месяцев',
        calc_sentence_1: 'сумма Вашего вклада составит ',
        calc_sentence_2: '',
      },
      visa_and_union_pay_debit_cards: {
        title: 'Дебетовые карты Visa® и Union Pay для вас и ваших партнеров',
        subtitle:
          'Виртуальная или банковская карта TIIB обеспечит вам доступ к вашим деньгам в любое время и в любом месте. Наша система 3D безопасности для онлайн-платежей защитит ваши финансы от мошенников. Вы можете использовать банкомат или оплатить покупку в любом магазине по всему миру. Ваши финансы не имеют границ с платежной картой Turkish International Investment Bank',
      },
      account_opening: {
        title: 'Запрос на открытие счета онлайн',
        subtitle: 'Вскоре наши специалисты свяжутся с вами для создания',
        text: 'Откройте счет сегодня в TIIB и почувствуйте свободу ваших денег!',
      },
      for_business_and_yours: {
        text: 'TURKISH INTERNATIONAL INVESTMENT BANK - это глобальное финансовое учреждение с личным подходом',
        line1:
          'Если у вас крупный бизнес или небольшое поручение - у нас может быть финансовое решение для вас!',
        line2:
          'Мы международные и мультивалютные, и так же близки, как банкомат за углом.',
        line3:
          'Совмещая надежность традиционного банка с высокотехнологичной безопасностью наших облачных электронных кошельков, мы предоставляем все, что вы ожидаете от вашего глобального банка!',
      },
      SEPA_SWIFT: {
        text1:
          'Мы предлагаем счета в различных валютах с доступом к европейской системе SEPA и международной системе SWIFT.',
        text2: 'Мы предоставляем множество типов счетов, например:',
        line1: 'Виртуальные и предоплаченные дебетовые карты.',
        line2: 'Быстрые переводы от пользователя к пользователю.',
        line3: 'Множество других отличных функций, управляемых онлайн.',
      },
      form_submissions: {
        people_trust_us: 'Нам доверяют!',
        name: 'Имя',
        phone: 'Телефон',
        message: 'Сообщение',
        email: 'Электронная почта',
        send: 'Отправить',
      },
      contact_information: {
        title1:
          'Мы предлагаем счета в различных валютах с доступом к Европейской системе SEPA и международной системе SWIFT.',
        reach_us: 'Вы можете связаться с нами:',
        title2: 'Найдите нас в социальных сетях и мессенджерах:',
      },
      contact: {
        contact: 'Наши',
        us: 'контакты',
        reach_us: 'Вы можете связаться с нами',
        welcome_to:
          'Добро пожаловать в Турецкий Международный Инвестиционный Банк',
        name: 'Имя',
        phone: 'Номер телефона',
        message: 'Сообщение',
        cyprus_title: 'Представительство на Кипре:',
        cyprus_address_1: 'ул. Спироса Киприану, 57,',
        cyprus_address_2: 'Бизнес-центр Bybloserve,',
        cyprus_address_3: 'Ларнака 6051, Кипр',
      },
      popup: {
        title: 'Send a request',
        subtitle: 'Наши специалисты вскоре свяжутся с Вами',
        name: 'Имя',
        phone: 'Номер телефона',
        email: 'E-mail',
        send: 'Отправить',
        success: 'Успешно!',
        ok: 'OK',
        title_opening: 'Запрос на Открытие Онлайн Счёта',
        download: 'Скачать Заявление на Открытие Счёта',
        personal: 'Заявление для открытия личного счета',
        corporate: 'Заявление для открытия корпоративного счета',
        upload: 'Загрузить Заполненное и Подписанное Заявление',
        add: 'Добавить',
        contact_info: 'Ваша контактная информация',
        oops: 'Упс',
        wrong: 'Что-то пошло не так!',
      },
      investor_relations: {
        investor: 'Инвестор',
        relations: 'Отношения',
        name: 'Турецкий Международный Инвестиционный Банк',
        trust: 'Люди нам доверяют',
        description:
          'Этот раздел предоставляет акционерам, аналитикам, средствам массовой информации и другим заинтересованным сторонам актуальную информацию, позволяя прозрачно оценить стоимость компании.',
      },
      login: {
        login: 'Логин',
        e_mail: 'E-mail/Имя пользователя',
        password: 'Пароль',
        dont_have_account: 'Если у Вас нет счёта',
        sign_up: 'зарегистрируйтесь',
        all_rights:
          'Турецкий Международный Инвестиционный Банк Лимитед. Все права защищены.',
        back: 'назад',
        terms_and_conditions: 'Условия и Положения',
        confirm: 'Подтвердить',
      },
      personal: {
        exit: 'Выход',
        details: 'Детали Аккаунта',
        statement: 'Выписка По Счёту',
        swift: 'Трансфер SWIFT/SEPA',
        exchange: 'Валютный Трансфер',
        internal: 'Внутренний Трансфер',
        transfer: 'ТРАНСФЕР',
        account_details: {
          title: 'Детали Клиента',
          id: 'ID Клиента:',
          company_name: 'Название Компании:',
          client_name: 'Имя Клиента:',
          contact_details: 'Контактная информация:',
          acc_num: 'Номер счёта',
          acc_type: 'Тип счёта',
          currency: 'Валюта',
          acc_balance: 'Баланс Счёта',
          sec_deposit: 'Залоговый Депозит',
        },
        statement_page: {
          title: 'Ваш Счёт',
          acc_num: 'Номер счёта',
          currency: 'Валюта',
          filter_title: 'Выберите счёт',
          date: 'Дата',
          transfer_type: 'Тип трансфера',
          payment: 'Назначение',
          transaction_number: 'Номер транзакции',
          debit: 'Дебет',
          credit: 'Кредит',
          status: 'Статус',
          balance: 'Баланс',
        },
        swift_page: {
          transactions_details: 'Детали Транзакции',
          swift_code: 'SWIFT Код',
          bank_name: 'Название Банка',
          country: 'Страна ',
          city: 'Город ',
          benef_address: 'Адрес Получателя',
          benef_name: 'Имя Получателя',
          acc_num: 'Номер счёта',
          bank_address: 'Адрес Банка',
          transfer_details: 'Детали Трансфера',
          amount: 'Сумма ',
          other: 'Другие Детали',
          purpose: 'Цель перевода',
          transfer: 'Перевести',
          i_agree_tu_p1: '',
          terms_tu: '',
          i_agree_tu_p2: '',
          i_agree_ru_p1: 'Я согласен с',
          terms_ru: ' Условиями и Положениями ',
          i_agree_ru_p2: 'Турецкого Международного Инвестиционного Банка',
          i_agree_en: '',
          terms_en: '',
        },
        exchange_and_internal_page: {
          exchange_title:
            'Конвертировать Валюту и/или Начать Валютный Трансфер',
          from_acc: 'Со Счёта',
          to_acc: 'На Счёт',
          select: 'Выбрать счёт',
          amount: 'Сумма',
          payment_ref: 'Назначение Платежа',
          internal_title: 'Начать Внутренний Трансфер',
        },
      },
      api_guide: {
        api: 'API',
        guide: 'Guide',
        start: 'Начните пользоваться',
        it_now: 'им сейчас!',
        difficulties:
          'Если у Вас возникли затруднения, пожалуйста, свяжитесь с нами',
        json: 'Parameters are passed in JSON format',
        headers_block: 'Headers block is required for all types of requests',
        example: 'Example:',
        available_endpoints: 'Available endpoints',
        log_in: 'Log In',
        response: 'Response',
        tool_signature_request: 'Tool Signature Request',
        transaction_status_request: 'Transaction status request',
        refund: 'Refund',
        transaction_info: 'Transaction info',
        transactions_list: 'Transactions list:',
      },
    },
  },
  tr: {
    translation: {
      website_title:
        'Yurtdışı Bankacılığı - Yurtdışı Banka Hesabı | Türk Uluslararası Yatırım Bankası',
      main: {
        turkey: 'TÜRKİYE',
        international: 'ULUSLARARASI',
        investment: 'YATIRIM',
        bank: 'BANKASI',
        login: 'GİRİŞ YAP',
        logout: 'çıkış yap',
        sign_up: 'Hesap Açma',
        contact_us: 'Bi̇ze Ulaşin',
        investor_relations: 'Yatirimci İli̇şki̇leri̇',
        banking: 'BANKACILIK',
        savings_account: 'VADELİ HESAP',
        debit_cards: 'Bankamati̇k Kartlari',
        e_money: 'E-PARA',
        about_us: 'HAKKIMIZDA',
        api_guide: 'API Kılavuzu',
        desc_title: 'Genel ve özel bankacılık hizmetlerinin tamamı',
        desc_subtitle:
          'Deneyimli, profesyonel idare ve mükkemel Yönetim Kurulu. Katı ama makul uyumluluk politikaları.',
        open_account: 'HESAP AÇMA',
        banking_service: 'Bankacılık Hizmeti',
        all_rights:
          'TÜRKİYE ULUSLARARASI YATIRIM BANKASI. Tüm hakları saklıdır.',
        banking_service_content:
          'Küresel  ve özel bankacılık sistemlerinin tamamında deneyimli ve profesyonel' +
          ' yönetim. Katı ama makul uyum politikaları. Küresel ödemeler ve yüksek mevduatlar için çok para birimli ' +
          'banka hesapları. Evrensel olarak kabul edilen banka kartları. Hızlı küresel para transferleri. ' +
          'Mükemmel hesap güvenliği ve veri koruması. Çevrimiçi olarak eksiksiz E-Bankacılık araçları paketi. ' +
          'Kripto varlığına sorunsuz ve kesintisiz bağlantı. Güler yüzlü, yetkin destek personeli. Yaptığımız ' +
          've Olduğumuz şey bu!',
        universal_personal_accounts: 'Evrensel kişisel hesaplar',
        universal_personal_accounts_desc:
          'TÜRKİYE ULUSLARARASI YATIRIM BANKASI, çevrimiçi olarak ' +
          'başvurulup açılabilen kişisel hesaplar sunmaktadır. Kişisel hesabınızın özellik paketi , ihtiyaçlarınıza ' +
          'mükemmel uyacak şekilde hazırlanacaktır .Genel bankacılık hesaplarımız, tüm ailenizin günlük finansal ' +
          'yaşamını basitleştirmek ve düzene sokmak için ihtiyacınız olan her şeyi sunar. TÜRKİYE ULUSLARASI ' +
          'YATIRIM BANKASI müşterileri, bankacılık ve yatırım portföylerinin dünya çapındaki sektör ' +
          'meslektaşlarımız arasında benzeri görülmemiş düzeyde kişiselleştirilmiş yönetimin keyfini çıkarmaktadır.',
        fueling_your_e_commerce: 'E-ticaretinizi körüklemek',
        fueling_your_e_commerce_desc:
          'TÜRKİYE ULUSLARARASI YATIRIM BANKASI, uluslararası e-ticaretinizi ' +
          'başlatmak ve yönetmek için ihtiyacınız olan her şeyi sağlar. Birden fazla para birimi yönetme, ' +
          'dünyanın önemli bölgelerindeki destek yerleri, muhabir banka ağımız ve uzman Bilgi Teknolojileri ' +
          'ekibi, aradığınız istikrarlı ve makul fiyatlı bir ortamda en zorlu e-ticaret misyonlarını başlatmak ' +
          've işletmek için tam olarak ihtiyacınız olan şeydir.',
        acquiring_services: 'Hizmet edinme',
        acquiring_services_desc_p1:
          'Türkiye Uluslararası Yatırım Bankası, sitenin en önemli hizmetlerinden birinin teknik desteğini gerçekleştirecek - Hizmet Edinme. Ödemelerin ne kadar hızlı ve doğru bir şekilde işleme alınacağına bağlı olduğundan sitedeki ödemelerin kabul edilmesi saat gibi çalışmalıdır. Yeni müşterilerinize çeşitli para çekme yöntemleri sunabileceksiniz.',
        acquiring_services_desc_p2: 'Bizimle işbirliği yapmak:',
        acquiring_services_desc_p3:
          '- Varlıkların ve müşterilerin kişisel verilerinin güvenliğinden emin olabilirsiniz.',
        acquiring_services_desc_p4: '- 7/24 nitelikli teknik destek.',
        acquiring_services_desc_p5:
          '- Kripto para biriminde ödeme kabul etme ve işleme imkanı.',
        acquiring_services_desc_p6: '- Sezgisel arayüz.',
        saving: 'Vadeli',
        account: 'Hesap',
        deposit_with: 'TÜRKİYE ULUSLARARASI YATIRIM BANKASI',
        investment_bank: 'ile Mevduat Hesabı',
        saving_desc:
          'TÜRKİYE ULUSLARARASI YATIRIM BANKASI, Vadeli Hesaplarındaki Mevduat Sertifikalarında ' +
          'USD ve EUR cinsinden %7’e kadar ödeme yapmaktadır. Hiçbir koşula bağlı kalmadan, sadece düz ' +
          've basit üç ayda bir faiz ödemeleri. Yararlanabilmek için mevcut bir TÜRKİYE ULUSLARARASI YATIRIM ' +
          'BANKASI hesabına sahip olmanız yeterli.',
        debit_cards_block_title: 'Banka Kartları',
        debit_cards_block_subtitle:
          'Sizin ve iş ortaklarınız için Visa® ve UnionPay banka kartları',
        debit_cards_block_desc:
          'TUYB sanal veya banka kartı, paranıza istediğiniz zaman ve her yerden ' +
          'erişmenizi sağlayacaktır. Çevrimiçi ödemeleriniz için 3D güvenlik sistemimiz,  mal varlığınızı ' +
          'dolandırıcılardan koruyacaktır. Herhangi bir ATM kullanabilir veya satın alma işleminiz için dünya ' +
          'çapındaki herhangi bir mağazada ödeme yapabilirsiniz. Finansmanınızın Türkiye Uluslararası Yatırım ' +
          'Bankası ödeme kartlarıyla sınırı yoktur.',
        debit_cards_block_desc2:
          "Bugün TUYB'da bir hesap açın ve paranızın özgürlüğünü hissedin!",
        e_money_title_1: 'İşiniz için, sizin için',
        e_money_desc_1:
          'TÜRKİYE ULUSLARARASI YATIRIM BANKASI, kişisel bir temaya sahip küresel bir finans ' +
          'kurumudur. Küçük, büyük bütün işleriniz için finansal bir çözüm olabilir! Hem uluslararası hem de ' +
          'çok para birimliyiz ve köşedeki ATM kadar yerliyiz. Tuğla ve harç istikrarını bulut tabanlı e-cüzdanlarımızın ' +
          'yüksek teknoloji güvenliği ile birleştirerek, küresel Bankanızın olmasını istediğiniz her şeyiz!',
        e_money_title_2: 'SEPA, SWIFT',
        e_money_desc_2:
          'Avrupa SEPA ve uluslararası SWIFT sistemlerine erişimi olan çeşitli para birimlerinde ' +
          'hesaplar sunuyoruz. Sanal ve ön ödemeli banka kartları, hızlı eşler arası transferler ve çevrimiçi ' +
          'olarak yönetilen diğer birçok harika özellik de dahil olmak üzere çok yönlü kişisel e-bankacılık ' +
          'çözümlerimiz sayesinde herkesin günlük ihtiyaçları karşılanırken, yüksek net gelire sahip müşterilerimiz ' +
          'için bireysel bankacılık hizmeti sağlanmaktadır.',
        privacy:
          '© 2023 TÜRKİYE ULUSLARARASI YATIRIM BANKASI. Tüm hakları saklıdır. ',
        terms: 'Hükümler ve Koşullar.',
        send: 'GÖNDER',
        valid_email: 'Geçerli bir e-posta adresi girin',
        all_fields: 'Bütün alanları doldurunuz.',
        slider: {
          TIIB: 'TÜRKİYE ULUSLARARASI YATIRIM BANKASI',
          experience: 'BENZERSİZ GENEL VE ÖZEL BANKACILIK HİZMETLERİ DENEYİMİ',
        },
      },
      home: {
        europe_asia_africa:
          "Avrupa, Asya ve Afrika'daki en büyük muhabir bankalar aracılığıyla dünya çapındaki üye işyerlerimize kapsamlı ödeme çözümleri sağlama konusunda uzmanız. CRS gerekliliklerine uymuyoruz, yani hiçbir bilgiyi hiçbir devlet kurumuyla paylaşmıyoruz.",
        FATCA:
          "FATCA gerekliliklerine uymuyoruz ve bu nedenle ABD'li müşterilerimiz hakkındaki bilgileri ABD'li yetkililerle paylaşmıyoruz.",
        TIIB_provides_corporate:
          'TÜRK ULUSLARARASI YATIRIM BANKASI, KURUMSAL VE BİREYSEL BANKACILIK HİZMETLERİNİN TAMAMINI SUNMAKTADIR:',
        gaming_gambling_forex:
          'Özellikle oyun, kumar, forex, kripto para borsası gibi yüksek riskli işletmeler için ödeme çözümleri sunuyoruz. Tüm ülkelere karşı esnekliğimiz var.',
        We_provide_various_types_of_accounts:
          'Aşağıdakiler gibi farklı hesap türleri sunuyoruz:',
        Shared_bank_account_with_a_common_IBAN:
          'Ortak IBAN numarasına sahip ortak banka hesabı. Tüm işlemler bankamızın adı kullanılarak yapılacağından kimliğini göstermek istemeyen kişiler için faydalıdır.',
        Dedicated_account_with_SWIFT:
          'SWIFT/SEPA kodlu özel hesap. Bu durumda tüm işlemler müşterilerin adına yapılacaktır.',
        Merchant_account_with_TIIB_Card:
          "TIBBİ Kartlı Tüccar hesabı, dünya çapındaki fonlarınızı yönetmek için ideal bir araç olacaktır. TIIB Hesabınıza doğrudan bağlı sanal veya gerçek bir banka kartıyla fonlara erişebilecek, Visa® markalı kartların kabul edildiği her yerde mağazada veya çevrimiçi alışveriş yapabilecek, 3D Secure Push ile güvenli çevrimiçi ödemeler yapabilecek, dünya çapındaki ATM'lerden nakit çekebilecek ve Visa® markalı banka kartlarıyla ilişkili diğer özelliklerin keyfini çıkara bileceksiniz.",
      },
      public_routes: {
        main_page: 'Ana Sayfa',
        general_information: 'Genel Bilgiler',
        organizational_structure: 'Organizasyon Yapısı',
        compliance_and_documents: 'Uyumluluk ve Belgeler',
        universal_personal_accounts: 'Evrensel Kişisel Hesaplar',
        acquiring_services: 'Hizmet alma',
        api_guide: 'API Kılavuzu',
        fueling_your_e_commerce: 'E-Ticaretinizi güçlendirmek',
        banking_service: 'Bankacılık Hizmeti',
        deposit_with_tiib: 'TIIB deki mevduat',
        calculate_amount_of_interest: 'Faiz Tutarını Hesapla',
        visa_and_union_pay_debit_cards: 'Visa® ve Union Pay Banka Kartları',
        account_opening: 'Hesap Açma',
        for_business_and_yours: 'İş İçin ve Sizin İçin',
        SEPA_SWIFT: 'SEPA, SWIFT',
        form_submissions: 'Form Gönderimleri',
        contact_information: 'İletişim Bilgileri',
      },
      banking_services: {
        swift: 'ULUSLARARASI SWİFT VE SEPA HAVALELERİ',
        safety: 'TASARRUF HESAPLARI',
        crypto: 'KRİPTO ÖDEMELERİ',
        my_card: 'BANKA KARTLARI',
        processingCards: 'KART İŞLEME',
        chart: 'YATIRIM PROGRAMLARI',
        money: 'ÇOKLU PARA BİRİMİ BANKA HESABI',
        infinite: 'SINIRSIZ TRANSFER',
        anonymous: 'ANONİM ÖDEMELER',
        distance: 'UZAKTAN HESAP AÇMA',
        support: 'ÖZEL YÖNETİCİ',
      },
      general_info: {
        banking_built_differently: 'BANKACILIK FARKLI YAPILIYOR',
        text1:
          'Daha İyi Banka Daha İyi Yaşam: Hayatınızı küçük yollarla kolaylaştırmak için çalışıyoruz. Müşterilerimizin her birinin bankacılık deneyimini, yeni zirvelere ve yeni hedeflere ulaşmalarına yardımcı olmak için kişiselleştiriyoruz.',
        relationships: 'MÜŞTERİ İLİŞKİLERİ',
        text2:
          'Sizi tanıyoruz çünkü sizi tanımak istiyoruz ve müşteri etkileşimine ve hizmetine değer veriyoruz. Diğer bankalarda siz hesap bakiyenizden başka bir şey değilsiniz.',
        flexibility_convenience: 'ESNEKLİK VE KONFOR',
        text3:
          'Karar vericilere kolay erişim sağlıyoruz ve tüm süreç boyunca aynı ekiple çalışıyorsunuz.',
        commitment: 'BAĞLILIK',
        text4:
          'Bankacılık ilişkimizin her aşamasında müşterilerimizi ve ürünlerimizi destekliyoruz.',
        longevity: 'DAYANIKLILIK',
        text5:
          'O zamandan beri aynı kişiler tarafından sahipleniliyor ve işletiliyor ve çok az ciromuz var. Diğer bankalarda sürekli birleşme ve satın almalar her zaman yeni bankalar, yeni şartlar ve sizi ve geçmişinizi bilmeyen yeni bankacılar anlamına gelir.',
        make_life_easier: 'HAYATINIZI KOLAYLAŞTIRIYORUZ',
        text6:
          'Tüm bu sebepler ve kelimelerle anlatılamayacak küçük sebeplerden dolayı hayatınızı kolaylaştırıyoruz. TIBBİ olarak hizmete odaklanmanın en iyi yolunun müşterilerimizi tanımak olduğuna inanıyoruz. İster evinizde, ister işyerinizde hayatınızı daha iyi hale getiren farklı bir banka olmaya çalışıyoruz. BU BİZİM MİSYONUMUZ.',
        text7:
          'Küçük bir bankanın tüm avantajlarını, büyük bir kurumun teknolojisi ve rahatlığıyla birleştiriyoruz. Gerçekten her iki dünyanın da en iyisi.',
      },
      organizational_structure: {
        head_board: 'Yönetim Kurulu Başkanı',
        board_member: 'Yönetim Kurulu Üyesi',
      },
      compliance_and_documents: {
        pdf1: 'TIIB-VERİ-KORUMA-POLİTİKASI.PDF',
        pdf2: 'TIIB-politikası.pdf',
        open: 'aç',
        download: 'indir',
      },
      universal_personal_accounts: {
        text1:
          'ULUSLARARASI TÜRK YATIRIM BANKASI, internet üzerinden başvurabileceğiniz ve açabileceğiniz kişisel hesaplar sunmaktadır. Kişisel hesap özellik paketiniz ihtiyaçlarınıza mükemmel şekilde uyacak şekilde tasarlanacaktır.',
        text2:
          'Hepsi bir arada banka hesaplarımız, tüm ailenizin günlük finansal yaşamını basitleştirmek ve optimize etmek için ihtiyacınız olan her şeyi sunar.',
        text3: 'Zamanınızı tasarruf ediyoruz!',
        text4:
          'ULUSLARARASI TÜRK YATIRIM BANKASI müşterileri, bankacılık ve yatırım portföylerinin kişiselleştirilmiş yönetiminden, dünya çapındaki sektör paydaşlarının eşi benzeri olmayan bir düzeyde yararlanmaktadır.',
      },
      acquiring_services: {
        text1:
          'Türkiye ULUSLARARASI YATIRIM BANKASI, sitenin en önemli hizmetlerinden biri olan satın alma sürecinde teknik destek sağlayacaktır',
        text2:
          'Sitede ödeme kabul etme işlemi saat gibi çalışmalıdır çünkü ödemelerin ne kadar hızlı ve doğru bir şekilde işleme alınacağını belirler. Yeni müşterilerinize farklı para çekme yöntemleri sunabileceksiniz.',
        accounts_types: 'Aşağıdakiler gibi birçok hesap türü sunuyoruz:',
        type1:
          'Müşterilerin varlıklarının ve kişisel verilerinin güvenliğini garanti etmek.',
        type2: '7/24 nitelikli teknik destek.',
        type3:
          'Kripto para birimlerindeki ödemeleri kabul etme ve işleme yeteneği.',
        type4: 'Sezgisel arayüz.',
      },
      fueling_your_e_commerce: {
        title1: 'TÜRK ULUSLARARASI YATIRIM BANKASI',
        subtitle1:
          'uluslararası e-ticareti başlatmak ve yönetmek için ihtiyacınız olan her şeyi sağlar.',
        card1: 'BİRDEN FAZLA PARA BİRİMİNİ YÖNETME YETENEĞİMİZ',
        card2: 'DÜNYANIN ÖNEMLİ BÖLGELERİNDEKİ DESTEK NOKTALARIMIZ',
        card3: 'GÜVENİLİR MUHABİR BANKA AĞIMIZ',
        card4: 'UZMAN BT EKİBİMİZ',
        title2:
          ' En zorlu e-ticaret işletme misyonlarını istikrarlı ve makul bir şekilde başlatmak ve işletmek için tam olarak ihtiyacınız olan şey budur.',
        subtitle2: 'Aradığınız fiyatlandırılmış ortam.',
      },
      banking_service: {
        title: 'BU BİZİM İŞİMİZ VE BİZ KİMİZ!',
        line1: 'Çok çeşitli genel ve özel bankacılık hizmetleri.',
        line2: 'Deneyimli, profesyonel yönetim ve seçkin Yönetim Kurulu.',
        line3: 'Katı ama makul uyumluluk politikası.',
        line4:
          'Küresel ödemeler için çoklu para birimi hesapları ve yüksek getirili mevduatlar.',
        line5: 'Evrensel olarak kabul edilen banka kartları.',
        line6: 'Zamanında uluslararası para transferleri (Western Union).',
        line7: 'Mükemmel hesap güvenliği ve veri koruması.',
        line8:
          'Çevrimiçi ve uygulamada eksiksiz bir elektronik bankacılık araçları seti.',
        line9: 'Kripto varlıklarına kusursuz bağlantı.',
        line10: 'Samimi ve bilgili destek personeli.',
      },
      deposit_with_tiib: {
        title:
          "ULUSLARARASI TÜRK YATIRIM BANKASI ABD Doları ve Euro tasarruf hesaplarındaki mevduat sertifikalarına %7'ye varan oranlarda ödeme yapmaktadır. Hiçbir koşula bağlı değil, yalnızca üç ayda bir faiz ödemeleri yapılıyor.",
        subtitle:
          'Katılmak için ULUSLARARASI TÜRK YATIRIM BANKASInda hesap sahibi olmanız gerekmektedir.',
      },
      calc: {
        title: 'Faiz miktarını hesaplayın',
        deposit_amount: 'Mevduat tutarı',
        period: 'süre',
        month: 'ay',
        currency: 'para birimi',
        open_account: 'HESAP AÇMA',
        interest: 'Faiz',
        in: '',
        month_2: '',
        calc_sentence_1: 'ayda mevuatınızdan',
        calc_sentence_2: 'faiz kazanabilirsiniz',
      },
      visa_and_union_pay_debit_cards: {
        title: 'SİZ VE ORTAKLARINIZ İÇİN VISA® VE UNİONPAY BANKA KARTLARI',
        subtitle:
          "TIIB sanal veya banka kartı, paranıza her zaman ve her yerden erişim sağlar. Online ödemeleriniz için 3D güvenlik sistemi, finansal dolandırıcılardan paranızı korur. Dünya genelindeki herhangi bir ATM'den para çekebilir veya herhangi bir mağazada alışveriş yapabilirsiniz. Türk Uluslararası Yatırım Bankası ödeme kartlarıyla finanslarınızın sınırları yoktur.",
      },
      account_opening: {
        title: 'Online Hesap Açma Talebi',
        subtitle:
          'En kısa zamanda uzmanlarımız kişisel bir hesap oluşturmak için sizinle iletişime geçecektir.',
        text: "Bugün TIIB'de hesap açın ve paranızın özgürlüğünü hissedin!",
      },
      for_business_and_yours: {
        text: 'TÜRK ULUSLARARASI YATIRIM BANKASI, KİŞİSEL BİR DOKUNUŞLA GLOBAL BİR FİNANSAL KURULUŞTUR',
        line1:
          'Eğer büyük bir işletme yönetiyorsanız veya sadece küçük bir iş için harekete geçiyorsanız - sizin için bir finansal çözümümüz olabilir!',
        line2:
          'Uluslararası ve çok para birimli yüz ve köşedeki ATM kadar yereliz.',
        line3:
          'Geleneksel bankacılık stabilitesini bulut tabanlı e-cüzdanlarımızın yüksek teknoloji güvenliği ile birleştirerek, küresel bankanızın olmasını istediğiniz her şeyiz!',
      },
      SEPA_SWIFT: {
        text1:
          'Avrupa SEPA ve uluslararası SWIFT sistemlerine erişimi olan birden fazla para biriminde hesaplar sunuyoruz.',
        text2: 'Örneğin, aşağıdaki hesap türlerini sağlıyoruz:',
        line1: 'Sanal ve ön ödemeli banka kartları.',
        line2: 'Eşler arası hızlı aktarımlar.',
        line3: 'Diğer birçok harika özellik çevrimiçi olarak kontrol edilir.',
      },
      form_submissions: {
        people_trust_us: 'İNSANLAR BİZE GÜVENİYOR!',
        name: 'İsim',
        phone: 'Telefon',
        message: 'Mesaj',
        email: 'E-posta',
        send: 'Gönder',
      },
      contact_information: {
        title1:
          'Avrupa SEPA ve uluslararası SWIFT sistemlerine erişimi olan birden fazla para biriminde hesaplar sunuyoruz.',
        reach_us: 'Bize ulaşabilirsiniz:',
        title2:
          'Bizi sosyal ağlarda ve anlık mesajlaşma uygulamalarında bulun:',
      },
      contact: {
        contact: 'BİZE ',
        us: 'ULAŞIN',
        reach_us: 'BİZE ULAŞABİLİRSİNİZ',
        welcome_to: 'Türkiye Uluslararasi Yatirim Bankasina Hoş Geldiniz',
        name: 'İsim',
        phone: 'Telefon',
        message: 'Mesaj',
        cyprus_title: 'Kıbrıs Temsilciliği:',
        cyprus_address_1: '57 Spyrou Kipriyanu Caddesi,',
        cyprus_address_2: 'Bybloserve İş Merkezi,',
        cyprus_address_3: 'Larnaka 6051, Kıbrıs',
      },
      popup: {
        title: 'İstek gönder',
        subtitle:
          'En kısa zamanda uzmanlarımız kişisel bir hesap oluşturmak için sizinle iletişime geçecektir.',
        name: 'İsim',
        phone: 'Telefon',
        email: 'E-posta',
        send: 'GÖNDER',
        success: 'Başarılı!',
        ok: 'Tamam',
        title_opening: 'Online Hesap Açma Talebi',
        download: 'Hesap Açma Formunu indirin',
        personal: 'Kişisel Hesap Açma Formu',
        corporate: 'Kurumsal Hesap Açma Formu',
        upload: 'Doldurulmuş ve İmzalanmış Formu yükleyin',
        add: 'EKLE',
        contact_info: 'İletişim Bilgileriniz',
        oops: 'Eyvah',
        wrong: 'Bir şeyler ters gitti!',
      },
      investor_relations: {
        investor: 'YATIRIMCI',
        relations: 'İLİŞKİLERİ',
        name: 'TÜRKİYE ULUSLARARASI YATIRIM BANKASI',
        trust: 'İnsanlar bize güveniyor',
        description:
          'Bu bölüm, hissedarlar, analistler, medya ve diğer ilgili taraflara ilgili bilgileri ' +
          'sağlayarak şirketin değerinin şeffaf bir şekilde değerlendirilmesini sağlar.',
        '': '',
      },
      login: {
        login: 'GİRİŞ',
        e_mail: 'E-posta/Kullanıcı Adı',
        password: 'Şifre',
        dont_have_account: 'Eğer hesabınız yoksa',
        sign_up: 'kayıt olun',
        all_rights:
          'TÜRKİYE ULUSLARARASI YATIRIM BANKASI. Tüm hakları saklıdır.',
        back: 'Geri',
        terms_and_conditions: 'Hükümler ve Koşullar',
        confirm: 'ONAY',
      },
      personal: {
        exit: 'ÇIKIŞ',
        details: 'Hesap Detayları',
        statement: 'Hesap Özeti',
        swift: 'SWIFT/SEPA Transferi',
        exchange: 'Takas İşlemi',
        internal: 'İç Transfer',
        transfer: 'TRANSFER ET',
        account_details: {
          title: 'Müşteri Bilgileri',
          id: 'Müşteri Kimliği:',
          company_name: 'Şirket Adı:',
          client_name: 'Müşteri Adı:',
          contact_details: 'İletişim Bilgileri:',
          acc_num: ' Hesap Numarası',
          acc_type: 'Hesap Türü',
          currency: 'Para Birimi',
          acc_balance: 'Hesap Bakiyesi',
          sec_deposit: 'Depozito',
        },
        statement_page: {
          title: 'Hesaplarınız',
          acc_num: 'Hesap Numarası',
          currency: 'Para Birimi',
          filter_title: 'Hesaba Göre Filtrele',
          date: 'Tarih',
          transfer_type: 'Gönderim Türü',
          payment: 'Ödeme',
          transaction_number: 'İşlem Numarası',
          debit: 'Borç',
          credit: 'Miktar',
          status: 'Durum',
          balance: 'Bakiye',
        },
        swift_page: {
          transactions_details: 'İşlem Detayları',
          swift_code: 'SWIFT Kodu',
          bank_name: 'Banka Adı',
          country: 'Ülke',
          city: 'Şehir',
          benef_address: 'Yararlanıcı Adresi',
          benef_name: 'Yararlanıcı Adı',
          acc_num: 'Hesap Numarası',
          bank_address: 'Banka Adresi',
          transfer_details: 'Transfer Detayları',
          amount: ' Miktar',
          other: 'Diğer Detaylar',
          purpose: 'Gönderim Amacı',
          i_agree_en: '',
          terms_en: '',
          i_agree_tu_p1: 'Türkiye Uluslararası Yatırım Bankasının',
          terms_tu: 'Hüküm ve Koşullarını',
          i_agree_tu_p2: 'kabul ediyorum.',
          i_agree_ru_p2: '',
          i_agree_ru_p1: '',
          terms_ru: '',
        },
        exchange_and_internal_page: {
          exchange_title:
            'Para Birimini Dönüştürme ve/veya Değişim Transferi Başlatma',
          from_acc: 'Hesaptan',
          to_acc: 'Hesaba',
          select: 'Hesap seç',
          amount: 'Miktar ',
          payment_ref: 'Ödeme Referansı',
          internal_title: 'Dahili Aktarım Başlatma',
        },
      },
      api_guide: {
        api: 'API',
        guide: 'Kılavuzu',
        start: 'Kullanmaya hemen ',
        it_now: 'başlayın',
        difficulties:
          'Herhangi bir zorluk yaşarsanız, lütfen bizimle iletişime geçin',
        json: 'Parametreler JSON formatında iletilir',
        headers_block: 'Her tür talep için başlık bloğu gereklidir',
        example: 'Örnek:',
        available_endpoints: 'Kullanılabilir uç noktalar',
        log_in: 'Giriş yapmak',
        response: 'Yanıt:',
        tool_signature_request: 'Araç İmza Talebi',
        transaction_status_request: 'İşlem durumu isteği',
        refund: 'İade',
        transaction_info: 'İşlem bilgileri',
        transactions_list: 'İşlem listesi:',
      },
    },
  },
}

const initLang = () => {
  if (localStorage.getItem('lang')) {
    return localStorage.getItem('lang')
  } else return 'en'
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: initLang(), // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
      keySeparator: '.',
    },
  })

export default i18n
