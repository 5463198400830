import React, {useState} from "react";
import Account from "../../Account";
import s from "./Swift.module.css";
import {AuthAPI} from "../../../Api/Api";
import MyPopupSuccess from "../../../../utils/MyPopupSuccess/MyPopupSuccess";
import {t} from "i18next";
import {withTranslation} from "react-i18next";
import MyPopupError from "../../../../utils/MyPopupError/MyPopupError";

const Swift = () => {

    const [swiftTransferData, setSwiftTransferData] = useState({
        "Amount": '',
        "Bank Name": '',
        "Bank Address": '',
        "SWIFT Code": '',
        "Beneficiary Country": '',
        "Beneficiary City": '',
        "IBAN/Beneficiary Account Number": '',
        "from_account": '',
        "Beneficiary Address": '',
        "Beneficiary Reference": '',
        "Beneficiary Name": '',
    })
    const [popupVisible, setPopupVisible] = useState(false)
    const [popupErrorVisible, setPopupErrorVisible] = useState(false)

    const createSwiftTransfer = () => {
        const token = localStorage.getItem('token')
        AuthAPI.createSwiftTransfer(token, swiftTransferData)
            .then(() => {
                setPopupVisible(true)
            }).catch(() => {
                setPopupErrorVisible(true)
        })
    }

    const getTermsAndConditionsLink = () => {
        if (localStorage.getItem('lang') === 'en') {
            return "/TURKISH_INTERNATIONAL_INVESTMENT_BANK_LIMITED_TERMS_AND_CONDITIONS.pdf"
        } else if (localStorage.getItem('lang') === 'tr') {
            return "/TURKIYE_UYB_SARTLAR_VE_KOSULLAR.pdf"
        } else {
            return "/Условия_и_Положения.pdf"
        }
    }

    return (
        <Account activeTab="swift-transfer" title={t('personal.swift_page.transactions_details')}>
            <MyPopupSuccess visible={popupVisible} setVisible={setPopupVisible}/>
            <MyPopupError visible={popupErrorVisible} setVisible={setPopupErrorVisible}/>
            <div>
                <div className={s.content}>
                    <div className={s.inputsWrapper}>
                        <div className={s.inputsColumn}>
                            <div className={s.fields}>
                                <div>
                                    <div className={s.spanBlock}>
                                        <span>{t('personal.swift_page.swift_code')}</span>
                                    </div>
                                    <div>
                                        <input
                                            className={s.fields__input}
                                            placeholder={t('personal.swift_page.swift_code')}
                                            value={swiftTransferData["SWIFT Code"]}
                                            onChange={(e) => {
                                                setSwiftTransferData({
                                                    ...swiftTransferData,
                                                    "SWIFT Code": e.currentTarget.value
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={s.fields}>
                                <div>
                                    <div className={s.spanBlock}>
                                        <span>{t('personal.swift_page.bank_name')}</span>
                                    </div>
                                    <div>
                                        <input
                                            className={s.fields__input}
                                            placeholder={t('personal.swift_page.bank_name')}
                                            value={swiftTransferData["Bank Name"]}
                                            onChange={(e) => {
                                                setSwiftTransferData({
                                                    ...swiftTransferData,
                                                    "Bank Name": e.currentTarget.value
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={s.fields}>
                                <div>
                                    <div className={s.spanBlock}>
                                        <span>{t('personal.swift_page.country')}</span>
                                    </div>
                                    <div>
                                        <input
                                            className={s.fields__input}
                                            placeholder={t('personal.swift_page.country')}
                                            value={swiftTransferData["Beneficiary Country"]}
                                            onChange={(e) => {
                                                setSwiftTransferData({
                                                    ...swiftTransferData,
                                                    "Beneficiary Country": e.currentTarget.value
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className={s.spanBlock}>
                                        <span>{t('personal.swift_page.city')}</span>
                                    </div>
                                    <div>
                                        <input className={s.fields__input}
                                               placeholder={t('personal.swift_page.city')}
                                               value={swiftTransferData["Beneficiary City"]}
                                               onChange={(e) => {
                                                   setSwiftTransferData({
                                                       ...swiftTransferData,
                                                       "Beneficiary City": e.currentTarget.value
                                                   })
                                               }}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={s.inputsColumn}>
                            <div className={s.fields}>
                                <div>
                                    <div className={s.spanBlock}>
                                        <span>{t('personal.swift_page.benef_address')}</span>
                                    </div>
                                    <div>
                                        <input
                                            className={s.fields__input}
                                            placeholder={t('personal.swift_page.benef_address')}
                                            value={swiftTransferData["Beneficiary Address"]}
                                            onChange={(e) => {
                                                setSwiftTransferData({
                                                    ...swiftTransferData,
                                                    "Beneficiary Address": e.currentTarget.value
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className={s.spanBlock}>
                                        <span>{t('personal.swift_page.benef_name')}</span>
                                    </div>
                                    <div>
                                        <input
                                            className={s.fields__input}
                                            placeholder={t('personal.swift_page.benef_name')}
                                            value={swiftTransferData["Beneficiary Name"]}
                                            onChange={(e) => {
                                                setSwiftTransferData({
                                                    ...swiftTransferData,
                                                    "Beneficiary Name": e.currentTarget.value
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={s.fields}>
                                <div>
                                    <div className={s.spanBlock}>
                                        <span>{t('personal.swift_page.acc_num')}</span>
                                    </div>
                                    <div>
                                        <input
                                            className={s.fields__input}
                                            placeholder={t('personal.swift_page.acc_num')}
                                            value={swiftTransferData["IBAN/Beneficiary Account Number"]}
                                            onChange={(e) => {
                                                setSwiftTransferData({
                                                    ...swiftTransferData,
                                                    "IBAN/Beneficiary Account Number": e.currentTarget.value
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className={s.spanBlock}>
                                        <span>{t('personal.swift_page.bank_address')}</span>
                                    </div>
                                    <div>
                                        <input
                                            className={s.fields__input}
                                            placeholder={t('personal.swift_page.bank_address')}
                                            value={swiftTransferData["Bank Address"]}
                                            onChange={(e) => {
                                                setSwiftTransferData({
                                                    ...swiftTransferData,
                                                    "Bank Address": e.currentTarget.value
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={s.transactionsDetails}>
                        <div>{t('personal.swift_page.transfer_details')}</div>
                    </div>

                    <div className={s.inputsWrapper}>
                        <div className={s.inputsColumn}>
                            <div className={s.spanBlock}>
                                <span>{t('personal.swift_page.acc_num')}</span>
                            </div>
                            <div>
                                <input
                                    className={s.fields__input}
                                    placeholder={t('personal.swift_page.acc_num')}
                                    value={swiftTransferData.from_account}
                                    onChange={(e) => {
                                        setSwiftTransferData({
                                            ...swiftTransferData,
                                            from_account: e.currentTarget.value
                                        })
                                    }}
                                />

                            </div>
                        </div>
                        <div>
                            <div className={s.spanBlock}>
                                <span>{t('personal.swift_page.amount')}</span>
                            </div>
                            <div>
                                <input
                                    className={s.fields__input}
                                    placeholder={t('personal.swift_page.amount')}
                                    type="number"
                                    value={swiftTransferData.Amount}
                                    onChange={(e) => {
                                        setSwiftTransferData({...swiftTransferData, Amount: e.currentTarget.value})
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={s.transactionsDetails}>
                        <div>{t('personal.swift_page.other')}</div>
                    </div>

                    <div className={s.fields}>
                        <div>
                            <div className={s.spanBlock}>
                                <span>{t('personal.swift_page.purpose')}</span>
                            </div>
                            <div>
                                <input
                                    className={s.fields__input}
                                    placeholder={t('personal.swift_page.purpose')}
                                    value={swiftTransferData["Beneficiary Reference"]}
                                    onChange={(e) => {
                                        setSwiftTransferData({
                                            ...swiftTransferData,
                                            "Beneficiary Reference": e.currentTarget.value
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div>
                        <div>
                            <input type="checkbox" className={s.checkbox}/>
                            <span
                                className={s.agree}>{t('personal.swift_page.i_agree_en')} {t('personal.swift_page.i_agree_tu_p1')} {t('personal.swift_page.i_agree_ru_p1')}</span>
                            <a
                                className={s.terms}
                                type="text"
                                target="_blank"
                                href={getTermsAndConditionsLink()}
                            >
                                <span
                                    className={s.terms}>{t('personal.swift_page.terms_tu')} {t('personal.swift_page.terms_en')} {t('personal.swift_page.terms_ru')}</span>
                            </a>
                            <span
                                className={s.agree}> {t('personal.swift_page.i_agree_tu_p2')} {t('personal.swift_page.i_agree_ru_p2')}</span>
                        </div>
                        <div>
                            <button
                                className={s.my_btn}
                                onClick={createSwiftTransfer}
                            >{t('personal.transfer')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Account>
    );
};

export default withTranslation()(Swift)
