import React, { useState } from 'react'
import MainScreen from '../../Components/mainScreen/MainScreen'
import s from './AccountOpening.module.css'
import MyPopupSuccess from '../../utils/MyPopupSuccess/MyPopupSuccess'
import MyPopupError from '../../utils/MyPopupError/MyPopupError'
import { t } from 'i18next'
import { API } from '../../utils/api/api'
import MyPopup from '../../utils/MyPopup/MyPopup'
import { AuthAPI } from '../../Components/Api/Api'

const AccountOpening = () => {
  const [popupSuccessVisible, setPopupSuccessVisible] = useState(false)
  const [popupIsVisible, setPopupIsVisible] = useState(false)
  const [error, setError] = useState('')
  const [popupErrorVisible, setPopupErrorVisible] = useState(false)

  const [data, setSendUserData] = useState({
    email: '',
    phone: '',
    name: '',
  })

  const preparedDataForSend = {
    project: 'turkey',
    subject: 'Hello!',
    data,
  }

  const sendMessage = async () => {
    if (
      data.email.search(
        '^[A-z0-9]{1,15}.?[A-z0-9]{1,15}@[a-z]{2,20}.[a-z]{2,4}$'
      ) === -1
    ) {
      const error = t('main.valid_email')
      setError(error)
    } else if (
      data.name.length <= 0 ||
      data.email.length <= 0 ||
      data.phone.length <= 0
    ) {
      const error = t('main.all_fields')
      setError(error)
    } else {
      setError('')
      await AuthAPI.send(preparedDataForSend)
        .then(() => setPopupSuccessVisible(true))
        .catch(() => setPopupErrorVisible(true))
    }
  }

  return (
    <div>
      <MainScreen
        title={t('public_routes.account_opening')}
        pageName={t('main.debit_cards')}
      />

      <div className={s.wrapper}>
        <div className={s.container}>
          <MyPopup visible={popupIsVisible} setVisible={setPopupIsVisible} />
          <div className={s.title}>{t('account_opening.text')}</div>
          <div className={s.mobileWrapper}>
            <MyPopupSuccess
              visible={popupSuccessVisible}
              setVisible={setPopupSuccessVisible}
            />
            <MyPopupError
              visible={popupErrorVisible}
              setVisible={setPopupErrorVisible}
            />

            <div className={s.popup_title}>{t('account_opening.title')}</div>

            <div className={s.flex}>
              <div className={s.popup_subtitle}>
                {t('account_opening.subtitle')}
              </div>
            </div>
            <div className={s.form}>
              <div className={s.input_block}>
                <span>{t('popup.name')}</span>
                <input
                  type='text'
                  value={data.name}
                  onChange={e => {
                    setSendUserData({ ...data, name: e.currentTarget.value })
                  }}
                />
              </div>
              <div className={s.input_block}>
                <span>{t('popup.phone')}</span>
                <input
                  type='text'
                  value={data.phone}
                  onChange={e => {
                    setSendUserData({ ...data, phone: e.currentTarget.value })
                  }}
                />
              </div>
              <div className={s.input_block}>
                <span>{t('popup.email')}</span>
                <input
                  type='text'
                  value={data.email}
                  onChange={e => {
                    setSendUserData({ ...data, email: e.currentTarget.value })
                  }}
                />
              </div>
            </div>

            <div className={s.flex}>
              <button className={s.my_btn} onClick={sendMessage}>
                {t('popup.send')}
              </button>
            </div>

            <div className={s.error}>{error}</div>
          </div>

          <div className={s.btn_block_mobile}>
            <button
              className={s.my_btn}
              onClick={() => {
                setPopupIsVisible(true)
              }}>
              {t('calc.open_account')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AccountOpening
