import React from "react";
import {Tab, Nav, Container, Stack} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import s from "./Account.module.css";
import {authSlice} from "../../Reducers/AuthReducer";
import {userSlice} from "../../Reducers/UserReducer";
import {withTranslation} from "react-i18next";
import {t} from "i18next";
import logo from "../../assets/newImg/logo.png";
import logoTrWhite from "../../assets/newImg/logo_tr_white.png";
import logoMobile from "../../assets/newImg/mobile_logo_personal.png";
import logOutMob from "../../assets/newImg/Log_out_mob.png";
import Sidebar from "../sidebar/Sidebar";

const Account = ({activeTab = "", title = "", children}) => {
    const state = useSelector((state) => state);
    const {AuthReducer: auth} = state;

    let dispatch = useDispatch();
    const {logout} = authSlice.actions;
    const {clearState} = userSlice.actions;

    const handleLogout = async () => {
        dispatch(logout())
        dispatch(clearState())
    };

    const getLogo = () => {
        if (localStorage.getItem('lang') === 'tr') {
            return <img src={logoTrWhite} alt="logo"/>
        } else {
            return <img src={logo} alt="logo"/>
        }
    }

    return (
        <div className={s.main_wrapper}>
            <div className={s.web}>
                <Tab.Container id="account-tab">
                    <Container fluid className={`p-0 ${s.navbarContainer}`}>
                        <Stack direction="horizontal" className="p-0">
                            <div className={`col-sm-3 ${s.logo}`}>
                                <Link to="/">
                                    {getLogo()}
                                </Link>
                            </div>
                            <div className={`col-sm-9 ${s.navbar}`}>
                                <Stack
                                    gap={3}
                                    direction="horizontal"
                                    className="justify-content-end"
                                >
                                    <Link to="/personal">
                                        <div className={s.loginButtonBlock}>
                                            <span className={s.login}>
                                              {auth?.user?.name || "Account"}
                                            </span>
                                        </div>
                                    </Link>
                                    <Link to="" onClick={handleLogout}>
                                        <div>
                                            <button className={s.signUpButton}>{t('personal.exit')}</button>
                                        </div>
                                    </Link>
                                </Stack>
                            </div>
                        </Stack>
                    </Container>

                    <Container fluid className={s.navbarContainer_mob}>
                        <Stack direction="horizontal" className="p-0">
                            <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'}/>
                            <div className={`col-sm-9 ${s.navbar}`}>
                                <Stack
                                    gap={3}
                                    direction="horizontal"
                                    className="justify-content-end"
                                >
                                    <Link to="/personal">
                                        <div className={s.loginButtonBlock}>
                                            <span className={s.login}>
                                              {auth?.user?.name || "Account"}
                                            </span>
                                        </div>
                                    </Link>
                                    <Link to="" onClick={handleLogout}>
                                        <div>
                                            <button className={s.signUpButton}>{t('personal.exit')}</button>
                                        </div>
                                    </Link>
                                </Stack>
                            </div>
                        </Stack>
                    </Container>

                    <Container fluid className="p-0">
                        <Stack
                            direction="horizontal"
                            className="p-0 justify-content-start align-items-start"
                        >
                            <div className={`col-sm-3 ${s.sidebar}`}>
                                <Nav className={`${s.nav} flex-column`}>
                                    <Nav.Item
                                        className={`${s.sidebarItem} ${activeTab === 'account-details' && s.sidebarActive}`}
                                    >
                                        <div
                                            className={`${s.details} ${activeTab === 'account-details' && s.detailsActive}`}></div>
                                        <Link to='/personal'>{t('personal.details')}</Link>
                                    </Nav.Item>

                                    <Nav.Item
                                        className={`${s.sidebarItem} ${activeTab === 'statement' && s.sidebarActive}`}
                                    >
                                        <div
                                            className={`${s.statement} ${activeTab === 'statement' && s.statementActive}`}></div>
                                        <Link to='/personal/statement'>{t('personal.statement')}</Link>
                                    </Nav.Item>

                                    <Nav.Item
                                        className={`${s.sidebarItem} ${activeTab === 'swift-transfer' && s.sidebarActive}`}
                                    >
                                        <div
                                            className={`${s.swift} ${activeTab === 'swift-transfer' && s.swiftActive}`}></div>
                                        <Link to='/personal/swift-transfer'>{t('personal.swift')}</Link>
                                    </Nav.Item>

                                    <Nav.Item
                                        className={`${s.sidebarItem} ${activeTab === 'exchange-transfer' && s.sidebarActive}`}
                                    >
                                        <div
                                            className={`${s.exchange} ${activeTab === 'exchange-transfer' && s.exchangeActive}`}></div>
                                        <Link to='/personal/exchange-transfer'>{t('personal.exchange')}</Link>
                                    </Nav.Item>

                                    <Nav.Item
                                        className={`${s.sidebarItem} ${activeTab === 'internal-transfer' && s.sidebarActive}`}
                                    >
                                        <div
                                            className={`${s.internal} ${activeTab === 'internal-transfer' && s.internalActive}`}></div>
                                        <Link to='/personal/internal-transfer'>{t('personal.internal')}</Link>
                                    </Nav.Item>

                                    <Nav.Item
                                        className={`${s.sidebarItem} ${activeTab === 'account-opening' && s.sidebarActive}`}
                                    >
                                        <div
                                            className={`${s.opening} ${activeTab === 'account-opening' && s.openingActive}`}></div>
                                        <Link to='/personal/account-opening'>{t('main.sign_up')}</Link>
                                    </Nav.Item>

                                </Nav>
                            </div>
                            <div className={`col-sm-9 ${s.content}`}>
                                <Container fluid className="py-5 px-5">
                                    <h1 className={`pb-4 ${s.title}`}>{title}</h1>
                                    <div>{children}</div>
                                </Container>
                            </div>
                        </Stack>
                    </Container>
                </Tab.Container>
            </div>
            <div className={s.mobile}>
                <div className={s.header_mob}>
                    <Link to="/">
                        <img src={logoMobile} alt=""/>
                    </Link>
                    <Link to="/personal">
                        <div>
                            <span className={s.login}>
                              {auth?.user?.name || "Account"}
                            </span>
                        </div>
                    </Link>
                    <Link to="" onClick={handleLogout}>
                        <img src={logOutMob} alt=""/>
                    </Link>
                </div>

                <div className={s.navbar}>
                    <Nav.Item
                        className={`${s.sidebarItem} ${activeTab === 'account-details' && s.sidebarActive}`}
                    >
                        <div
                            className={`${s.details} ${activeTab === 'account-details' && s.detailsActive}`}></div>
                        <Link to='/personal'>{t('personal.details')}</Link>
                    </Nav.Item>

                    <Nav.Item
                        className={`${s.sidebarItem} ${activeTab === 'statement' && s.sidebarActive}`}
                    >
                        <div
                            className={`${s.statement} ${activeTab === 'statement' && s.statementActive}`}></div>
                        <Link to='/personal/statement'>{t('personal.statement')}</Link>
                    </Nav.Item>

                    <Nav.Item
                        className={`${s.sidebarItem} ${activeTab === 'swift-transfer' && s.sidebarActive}`}
                    >
                        <div
                            className={`${s.swift} ${activeTab === 'swift-transfer' && s.swiftActive}`}></div>
                        <Link to='/personal/swift-transfer'>{t('personal.swift')}</Link>
                    </Nav.Item>

                    <Nav.Item
                        className={`${s.sidebarItem} ${activeTab === 'exchange-transfer' && s.sidebarActive}`}
                    >
                        <div
                            className={`${s.exchange} ${activeTab === 'exchange-transfer' && s.exchangeActive}`}></div>
                        <Link to='/personal/exchange-transfer'>{t('personal.exchange')}</Link>
                    </Nav.Item>

                    <Nav.Item
                        className={`${s.sidebarItem} ${activeTab === 'internal-transfer' && s.sidebarActive}`}
                    >
                        <div
                            className={`${s.internal} ${activeTab === 'internal-transfer' && s.internalActive}`}></div>
                        <Link to='/personal/internal-transfer'>{t('personal.internal')}</Link>
                    </Nav.Item>

                    {/*<Nav.Item*/}
                    {/*    className={`${s.sidebarItem} ${activeTab === 'account-opening' && s.sidebarActive}`}*/}
                    {/*>*/}
                    {/*    <div*/}
                    {/*        className={`${s.opening} ${activeTab === 'account-opening' && s.openingActive}`}></div>*/}
                    {/*    <Link to='/personal/account-opening'>Account Opening</Link>*/}
                    {/*</Nav.Item>*/}
                </div>

                <div className={`col-sm-9 ${s.content}`}>
                    <Container>
                        <h1 className={s.title}>{title}</h1>
                        <div>{children}</div>
                    </Container>
                </div>

            </div>
        </div>
    );
};

export default withTranslation()(Account)
