import React from 'react'
import { Row, Col, Stack, Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import Account from '../../Account'
import s from './AccountDetails.module.css'
import { withTranslation } from 'react-i18next'
import { t } from 'i18next'

const AccountDetails = () => {
  const state = useSelector(state => state)
  const { AuthReducer: auth, UserReducer: user } = state
  return (
    <Account
      activeTab='account-details'
      title={t('personal.account_details.title')}>
      <div className={s.web}>
        <div>
          <Row>
            <Col sm={6}>
              <Stack className='pb-2' direction='horizontal' gap={3}>
                <span className={s.user_data_title}>
                  {t('personal.account_details.id')}
                </span>
                <b>{user?.data?.client_id}</b>
              </Stack>
            </Col>
            <Col sm={6}>
              <Stack className='pb-2' direction='horizontal' gap={3}>
                <span className={s.user_data_title}>
                  {t('personal.account_details.company_name')}
                </span>
                <b>{auth?.user?.companyName}</b>
              </Stack>
            </Col>
            <Col sm={6}>
              <Stack className='pb-2' direction='horizontal' gap={3}>
                <span className={s.user_data_title}>
                  {t('personal.account_details.client_name')}
                </span>
                <b>{auth?.user?.name}</b>
              </Stack>
            </Col>
            <Col sm={6}>
              <Stack className='pb-2' direction='horizontal' gap={3}>
                <span className={s.user_data_title}>
                  {t('personal.account_details.contact_details')}
                </span>
                <b>{auth?.user?.email}</b>
              </Stack>
            </Col>
          </Row>
        </div>
        <div className='py-4'>
          <Table hover>
            <thead>
              <tr>
                <th>{t('personal.account_details.acc_num')}</th>
                <th>{t('personal.account_details.acc_type')}</th>
                <th>{t('personal.account_details.currency')}</th>
                <th>{t('personal.account_details.acc_balance')}</th>
                <th>{t('personal.account_details.sec_deposit')}</th>
              </tr>
            </thead>
            <tbody>
              {user?.data?.accounts && user?.data?.accounts.length > 0 ? (
                user?.data?.accounts.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className={s.td}>{item?.account_special_number}</td>
                      <td className={s.td}>{item?.account_type_name}</td>
                      <td className={s.td}>{item?.currency_abbreviation}</td>
                      <td className={s.td}>{item?.balance}</td>
                      <td className={s.td}>{item?.min_balance}</td>
                    </tr>
                  )
                })
              ) : (
                <tr span='6'>
                  <td>
                    <small className='tex-danger'>Hesap bulunamadı</small>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>

      <div className={s.mob}>
        <Row>
          <Col sm={6}>
            <Stack className='pb-2' direction='horizontal' gap={3}>
              <span className={s.user_data_title}>
                {t('personal.account_details.id')}
              </span>
              <span className={s.user_data_value}>{user?.data?.client_id}</span>
            </Stack>
          </Col>
          <Col sm={6}>
            <Stack className='pb-2' direction='horizontal' gap={3}>
              <span className={s.user_data_title}>
                {t('personal.account_details.company_name')}
              </span>
              <span className={s.user_data_value}>
                {auth?.user?.companyName}
              </span>
            </Stack>
          </Col>
          <Col sm={6}>
            <Stack className='pb-2' direction='horizontal' gap={3}>
              <span className={s.user_data_title}>
                {t('personal.account_details.client_name')}
              </span>
              <span className={s.user_data_value}>{auth?.user?.name}</span>
            </Stack>
          </Col>
          <Col sm={6}>
            <Stack className='pb-2' direction='horizontal' gap={3}>
              <span className={s.user_data_title}>
                {t('personal.account_details.contact_details')}
              </span>
              <span className={s.user_data_value}>{auth?.user?.email}</span>
            </Stack>
          </Col>
        </Row>

        {user?.data?.accounts && user?.data?.accounts.length > 0 ? (
          user?.data?.accounts.map((item, index) => {
            return (
              <div
                className={s.account_card}
                key={item?.account_special_number}>
                <div className={s.card_row}>
                  <div className={s.acc_title}>
                    {t('personal.account_details.acc_num')}:
                  </div>
                  <div className={s.acc_value}>
                    {item?.account_special_number}
                  </div>
                </div>
                <div className={s.card_row}>
                  <div className={s.acc_title}>
                    {t('personal.account_details.acc_type')}:
                  </div>
                  <div className={s.acc_value}>{item?.account_type_name}</div>
                </div>
                <div className={s.card_row}>
                  <div className={s.acc_title}>
                    {t('personal.account_details.currency')}:
                  </div>
                  <div className={s.acc_value}>
                    {item?.currency_abbreviation}
                  </div>
                </div>
                <div className={s.card_row}>
                  <div className={s.acc_title}>
                    {t('personal.account_details.acc_balance')}:
                  </div>
                  <div className={s.acc_value}>{item?.balance}</div>
                </div>
                <div className={s.card_row}>
                  <div className={s.acc_title}>
                    {t('personal.account_details.sec_deposit')}:
                  </div>
                  <div className={s.acc_value}>{item?.min_balance}</div>
                </div>
              </div>
            )
          })
        ) : (
          <tr span='6'>
            <td>
              <small className='tex-danger'>Hesap bulunamadı</small>
            </td>
          </tr>
        )}
      </div>
    </Account>
  )
}

export default withTranslation()(AccountDetails)
